import React, { useState } from 'react'
import { css } from '@emotion/react'

const getSlug = (str) => {
str = str.replace(/\s+/g, '-').toLowerCase();

return str
}


const checkIcon = [
	css`
	  position: absolute;
	  top: 0;
	  z-index: 40;
	  left: 1rem;
	  bottom: 0;
	  right: auto;
	  margin: auto;
	  height: 22px;
	  width: 22px;
	  border-radius: 50%;
	  background-color: transparent;
	  border: 1px solid var(--color-black);
	  background-color: var(--color-background);

  &:after {
	  content: "";
	  position: absolute;
	  display: none;
	  left: 8px;
	  top: 4px;
	  width: 5px;
	  height: 10px;
	  border: solid var(--color-sand);
	  border-width: 0 2px 2px 0;
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  transform: rotate(45deg);
  }

	`
]

const checkboxStyle = [
	css`
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	  z-index: 50;
	  width: 100%;
	  height: 100%;

	  &:checked ~ .checkmark {
		  background-color: var(--color-black);
		  border: 1px solid var(--color-black);
	  }

	  &:checked ~ .checkmark:after {
		  display: block;
	  }
	`
]


const CheckBox = ( {type, name, className, width, label, ...rest} ) => {

	return (
		<div className={`px-2 md:px-3 lg:px-4 ${width}`}>
		<label className="px-5 mb-2 -mx-4 flex justify-start items-center flex-row relative" htmlFor={getSlug(name)}>
			<span className="block uppercase text-xs mb-2 font-semibold hidden">{name}</span>

			<input id={getSlug(name)} type={type} name={getSlug(name)} className={`${className}`} css={[
				checkboxStyle
			]} {...rest} />
		<span className="checkmark" css={[
				checkIcon
			]} ></span>
			<div
				className="text-xs font-semibold tracking-wide pl-6"
				style={{lineHeight: '168%'}}
				dangerouslySetInnerHTML={{__html: label}}
				/>
		</label>
		</div>
	)
}

CheckBox.defaultProps = {
	type: `checkbox`,
	name: `Checkbox`,
	className: `input`,
	width: `flex-1`,
	label: ``,
}

const AcceptBox = props => {

	const {type, name, placeholder, className, width, children, ...rest} = props;


	 const [isChecked, setIsChecked] = useState(true);

	 const toggleChecked = () => {
	  setIsChecked(!isChecked);
	};

	return (
		<div className={`pt-2 pb-3 px-2 md:px-3 lg:px-4 ${width}`} {...rest}>
		<label className="px-5 mb-2 flex justify-start items-center flex-row relative" htmlFor={getSlug(name)}>
			<span className="block uppercase font-secondary mb-2 font-medium hidden text-gray-500">{name}</span>

			<input id={getSlug(name)} type={type} name={getSlug(name)} className={`${className}`} css={[
				checkboxStyle
			]} checked={isChecked}
                onChange={toggleChecked} />
		<span className="checkmark" css={[
				checkIcon
			]} ></span>
			<div
				className="text-sm leading-tight font-light font-primary tracking-wide pl-7 md:pl-9 lg:pr-5 relative z-50"
				style={{lineHeight: '135%', fontSize: '14px'}}
				>
				{children}
				</div>
		</label>
		</div>
	)
}

AcceptBox.defaultProps = {
	type: `checkbox`,
	name: `Checkbox`,
	placeholder: null,
	className: `input`,
	width: `flex-1`,
	children: `By leaving this box checked, you are accepting the terms detailed in our Privacy Policy.`,
}

export default AcceptBox
