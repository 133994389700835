import React from "react"


const CanadaLogo = () => {

	return (

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="partnerLogo"
	 viewBox="0 0 232.4 31.4">
<g>
	<g>
		<path d="M139.7,11.9l-0.6-8.8c-0.1-0.2-0.4-0.2-0.4,0c0,1.9-3.4,0.3-4.6,0c-10.4-2.4-17.4,6-17.4,12.9l0,0c0,9,5.9,13.8,13.5,13.8
			c6.1,0,9.1-3.9,10.1-8.1c0.1-0.5-0.5-0.4-0.6,0c-1.8,5.9-6.4,7.3-8.6,7.3c-4.9-0.1-9.5-4-9.6-13.1c-0.2-11.5,12.8-19,17.7-4.2
			C139.4,12,139.6,12.1,139.7,11.9z"/>
	</g>
	<path d="M143.9,13.3c2.7-1.1,9.9-0.8,9.9,2.5V26c0,2.8,2.8,3.7,3.5,1.9c0.2-0.2,0.7-0.1,0.5,0.4c-0.5,2-5.4,2.3-7.1-0.4
		c-9.4,6.8-15.3-6.6-4.1-6.8c3.5-0.5,3.9-2.5,3.9-4c0-3.5-1.8-3.8-2.9-3.8c-1.2,0-2.1,0.4-2.8,2.2C143.9,19.1,138.5,15.9,143.9,13.3
		z M146.3,29c2.2,0.3,4.2-1.7,4.2-3.7v-4.8C141,24,144,28.8,146.3,29z"/>
	<g>
		<path d="M162.8,12.9l-4.6,0.6c-0.3,0.1-0.3,0.2,0.2,0.4c1,0,1.6,0.7,1.6,2.2v10.6c0,1.3-1.8,2.4-2.2,2.5c-0.8,0.4,0.4,0.3,0.4,0.3
			h7.4c0.2,0,1,0.1,0-0.4c-1.1-0.4-2.4-1.3-2.4-2.4c0-1.1,0-8,0-8c0-3.4,2.2-5.2,4.3-5.2c2.3,0,4.1,0.5,4.1,5.2v8
			c0,1.2-1.5,2.2-2.3,2.5c-0.5,0.1-0.7,0.3-0.3,0.3h7.9c0.4,0,0.6-0.1,0.2-0.2c-1.9-0.7-2.2-2.2-2.2-2.6v-9.3c0-5.3-5.1-4.6-6.9-4.6
			c-2.3,0-4.7,1.4-4.9,2.1C163.5,13.1,163.3,12.9,162.8,12.9z"/>
	</g>
	<path d="M208.1,28.2c-0.8,1.3-2.7,1.7-5,1.7c-4.5,0-8.2-3.5-8.2-8.2c0-4.2,2.4-8.9,8.4-8.9c5.4,0,5.4,3.6,5.4,0.6V8
		c0-2.8-1.1-3.6-1.9-3.8c-1.2-0.4-2.8-0.5-0.3-0.5l4.7-0.1c-0.3,0.1,0.3,0.1,0.3,0.5v20.8c0,1.4,0.5,3.7,2.9,4.3
		c0.2,0,0.5,0.2,0.2,0.2l-5.2,0.6c-0.7,0.1-1.1-0.3-1.4-1.7 M203.5,29c2.8,0,4.7-1.1,4.7-7.7c0-6.2-1.9-7.8-4.7-7.8
		c-2.8,0-5.1,3.6-5.1,7.8C198.4,25.6,200.7,29,203.5,29z"/>
	<path d="M181.2,13.3c2.7-1.1,10-0.8,10,2.5V26c0,2.8,2.8,3.7,3.5,1.9c0.2-0.2,0.7-0.1,0.5,0.4c-0.5,2-5.4,2.3-7.1-0.4
		c-9.4,6.8-15.3-6.6-4.1-6.8c3.5-0.5,3.9-2.5,3.9-4c0-3.5-1.8-3.8-2.9-3.8c-1.2,0-2.1,0.4-2.8,2.2C181.1,19.1,175.7,15.8,181.2,13.3
		z M183.6,29c2.2,0.3,4.2-1.7,4.2-3.7v-4.8C178.3,24,181.2,28.8,183.6,29z"/>
	<path d="M217.8,13.3c2.7-1.1,9.9-0.8,9.9,2.5V26c0,2.8,2.8,3.7,3.5,1.9c0.2-0.2,0.7-0.1,0.5,0.4c-0.5,2-5.4,2.3-7.1-0.4
		c-9.3,6.8-15.3-6.6-4.1-6.8c3.5-0.5,3.9-2.5,3.9-4c0-3.5-1.8-3.8-2.9-3.8c-1.2,0-2.1,0.4-2.8,2.2C217.8,19.1,212.4,15.9,217.8,13.3
		z M220.2,29c2.2,0.3,4.2-1.7,4.2-3.7v-4.8C214.9,24,217.9,28.9,220.2,29z"/>
	<g>
		<g>
			<rect x="213.7" y="3.6" className="cl-1" width="3.9" height="7.9"/>
		</g>
		<g>
			<rect x="226.2" y="3.6" className="cl-1" width="3.9" height="7.9"/>
		</g>
		<g>
			<path className="cl-1" d="M221.4,5.2l0.6-1.1l0.6,1.1c0.1,0.1,0.1,0.1,0.2,0l0.5-0.2l-0.3,1.5c-0.1,0.3,0.1,0.4,0.3,0.2l0.7-0.7
				l0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.1l0.7-0.1l-0.2,0.9l0,0c0,0.1-0.1,0.2,0,0.3l0.2,0.1l-1.4,1.2c-0.1,0.2-0.1,0.2,0,0.4l0.1,0.4
				l-1.3-0.2c-0.2,0-0.3,0-0.3,0.1l0.1,1.5h-0.4l0.1-1.5c0-0.2-0.1-0.1-0.4-0.1l-1.2,0.2l0.2-0.4c0.1-0.2,0.1-0.3-0.1-0.4l-1.5-1.2
				l0.3-0.2c0.1-0.1,0.1-0.1,0-0.3l-0.3-0.9l0.7,0.2c0.2,0,0.3,0,0.3-0.1L220,6l0.7,0.8c0.1,0.1,0.3,0,0.2-0.2l-0.3-1.6l0.5,0.3
				C221.2,5.3,221.3,5.3,221.4,5.2L221.4,5.2z"/>
		</g>
	</g>
	<g>
		<line x1="110" y1="2.7" x2="110" y2="28.3"/>
		<rect x="109.9" y="2.7" width="0.3" height="25.6"/>
	</g>
	<g>
		<path className="cl-1" d="M3.5,2.6C3.5,2.3,3.4,2.2,3.4,2.1C3.3,1.9,3.2,1.9,2.8,1.9H2.3v2.7c0,0.4,0,0.5,0.6,0.5v0.2H1.4V5.1
			c0.5,0,0.6-0.1,0.6-0.5V1.9H1.5c-0.4,0-0.5,0-0.6,0.2C0.9,2.2,0.8,2.3,0.8,2.6H0.6c0-0.3,0.1-0.7,0.1-1h0.1
			c0.1,0.1,0.2,0.1,0.3,0.1h2.2c0.1,0,0.2,0,0.3-0.1h0.1c0,0.3,0,0.7,0.1,1L3.5,2.6z"/>
		<path className="cl-1" d="M5.5,5.3V5.2c0.3,0,0.4-0.1,0.4-0.4V3.8c0-0.5-0.2-0.7-0.6-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2H4V5.2c0.3,0,0.4-0.1,0.4-0.4V2.1c0-0.3,0-0.4-0.4-0.4V1.6c0.3,0,0.6-0.1,0.8-0.2v1.8
			C4.9,3,5.2,2.8,5.5,2.8c0.5,0,0.8,0.3,0.8,1v1c0,0.4,0,0.4,0.4,0.4v0.2H5.5z"/>
		<path className="cl-1" d="M7,5.3V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.3,0-0.4-0.4-0.4V3C7.3,3,7.6,2.9,7.8,2.8v1.9c0,0.4,0,0.4,0.4,0.4
			v0.2H7z M7.3,2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3C7.4,2.3,7.3,2.2,7.3,2z"/>
		<path className="cl-1" d="M9.9,3.5C9.8,3.2,9.7,3,9.4,3C9.2,3,9,3.2,9,3.4c0,0.3,0.2,0.4,0.5,0.5c0.4,0.2,0.7,0.4,0.7,0.7
			c0,0.5-0.5,0.8-0.9,0.8C9,5.4,8.7,5.3,8.7,5.2c0-0.1-0.1-0.5-0.1-0.7l0.2,0c0.1,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.2,0.4-0.4
			c0-0.2-0.2-0.4-0.4-0.5C9,4.1,8.7,3.9,8.7,3.5c0-0.4,0.3-0.7,0.8-0.7c0.2,0,0.4,0,0.5,0.1C10,3,10.1,3.3,10.1,3.5L9.9,3.5z"/>
		<path className="cl-1" d="M13.1,2.9c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1,0.5,1,1.1c0,0.9-0.7,1.4-1.4,1.5c-0.2,0-0.3-0.1-0.4-0.1V6
			c0,0.4,0,0.5,0.5,0.5v0.2h-1.3V6.5c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.4,0-0.4-0.4-0.4V3c0.2,0,0.5-0.1,0.8-0.2v0.4L13.1,2.9z
			 M12.5,4.9C12.6,5,12.8,5.1,13,5.1c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-0.9-0.8-0.9c-0.2,0-0.4,0.1-0.6,0.3V4.9z"/>
		<path className="cl-1" d="M15.4,3.4c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3V5.2C15,5.1,15,5.1,15,4.7V3.6
			c0-0.4,0-0.4-0.4-0.4V3c0.3,0,0.5-0.1,0.7-0.2L15.4,3.4L15.4,3.4z"/>
		<path className="cl-1" d="M17.9,2.8c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2C16.7,3.2,17.4,2.8,17.9,2.8
			L17.9,2.8z M17.1,4c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1c0-0.6-0.3-1.2-0.8-1.2C17.5,3,17.1,3.3,17.1,4z"/>
		<path className="cl-1" d="M19.4,3c0.3,0,0.6-0.1,0.8-0.2v1.9c0,0.4,0,0.6-0.1,0.9c-0.2,0.6-0.5,1-0.6,1.1c0,0-0.1,0-0.1,0
			c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0.1-0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.2-0.4c0-0.2,0.1-0.5,0.1-1.1V3.6
			c0-0.4,0-0.4-0.4-0.4V3z M19.7,2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3C19.8,2.3,19.7,2.2,19.7,2z"
			/>
		<path className="cl-1" d="M22.9,4.9c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L22.9,4.9z
			 M21.9,3c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C22.5,3.3,22.2,3,21.9,3L21.9,3z"/>
		<path className="cl-1" d="M25.3,4.8c-0.2,0.3-0.6,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.2-0.8,0.6-1.1c0.3-0.2,0.6-0.3,0.8-0.3h0
			c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0c-0.2-0.2-0.4-0.2-0.6-0.2
			c-0.4,0-0.7,0.3-0.7,0.9c0,0.8,0.5,1,0.8,1c0.2,0,0.4-0.1,0.7-0.3L25.3,4.8z"/>
		<path className="cl-1" d="M26.8,5.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7V3.1h-0.4l0-0.1l0.2-0.2H26V2.5l0.3-0.4l0.1,0v0.7
			H27c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5C26.9,5.1,27,5,27.1,5l0.1,0.1L26.8,5.3z"/>
		<path className="cl-1" d="M30.1,5.3V5.2c0.3,0,0.4-0.1,0.4-0.4V3.8c0-0.5-0.2-0.7-0.6-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2V5.2c0.3,0,0.4-0.1,0.4-0.4V2.1c0-0.3,0-0.4-0.4-0.4V1.6c0.3,0,0.6-0.1,0.8-0.2v1.8
			c0.2-0.2,0.5-0.4,0.8-0.4c0.5,0,0.8,0.3,0.8,1v1c0,0.4,0,0.4,0.4,0.4v0.2H30.1z"/>
		<path className="cl-1" d="M33.3,5.4c-0.1,0-0.2,0-0.2-0.1C33,5.2,33,5.1,33,5c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6C32.4,4,32.8,3.9,33,3.8V3.7c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L33.3,5.4z M33,4c-0.1,0.1-0.4,0.2-0.6,0.2
			C32.2,4.3,32,4.4,32,4.6c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V4z"/>
		<path className="cl-1" d="M35.5,3.5C35.4,3.2,35.2,3,34.9,3c-0.2,0-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.5,0.5c0.4,0.2,0.7,0.4,0.7,0.7
			c0,0.5-0.5,0.8-0.9,0.8c-0.3,0-0.6-0.1-0.6-0.2c0-0.1-0.1-0.5-0.1-0.7l0.2,0c0.1,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.2,0.4-0.4
			c0-0.2-0.2-0.4-0.4-0.5c-0.4-0.2-0.7-0.3-0.7-0.8c0-0.4,0.3-0.7,0.8-0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0.1,0.6L35.5,3.5z"
			/>
		<path className="cl-1" d="M38.4,2.9c0.1,0,0.2,0,0.2,0c0.6,0,1.1,0.5,1.1,1.2c0,0.8-0.7,1.4-1.4,1.4c-0.2,0-0.5-0.1-0.8-0.2V2.1
			c0-0.3,0-0.4-0.4-0.4V1.6c0.2,0,0.6-0.1,0.8-0.2v1.7L38.4,2.9z M37.9,4.7c0,0.1,0,0.1,0,0.2c0,0.1,0.2,0.3,0.5,0.3
			c0.5,0,0.8-0.4,0.8-1c0-0.6-0.4-1-0.9-1c-0.2,0-0.4,0.1-0.5,0.1V4.7z"/>
		<path className="cl-1" d="M42.2,4.9c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L42.2,4.9z
			 M41.3,3c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C41.8,3.3,41.6,3,41.3,3L41.3,3z"/>
		<path className="cl-1" d="M44.6,4.9c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1C43,4.7,43.5,5,43.8,5c0.2,0,0.4-0.1,0.6-0.3L44.6,4.9z
			 M43.6,3c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C44.2,3.3,44,3,43.6,3L43.6,3z"/>
		<path className="cl-1" d="M46.5,5.3V5.2c0.3,0,0.4-0.1,0.4-0.5V3.8c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2H45V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.4,0-0.4-0.3-0.4V3c0.3,0,0.5-0.1,0.7-0.2v0.4
			C45.9,3.2,46,3.1,46.1,3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H46.5z"/>
		<path className="cl-1" d="M52.3,5.3V5.2c0.3,0,0.4-0.1,0.4-0.4V3.8c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.4,0.1-0.7,0.3c0,0.1,0,0.1,0,0.2
			v1.1c0,0.4,0.1,0.4,0.4,0.4v0.2h-1.2V5.2c0.4,0,0.4-0.1,0.4-0.4V3.8c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.2-0.7,0.3v1.3
			c0,0.4,0,0.4,0.3,0.4v0.2h-1.1V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.4,0-0.4-0.4-0.4V3c0.2,0,0.5-0.1,0.7-0.2v0.5
			c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.5,0.2,0.6,0.5C51.7,3.1,51.8,3,52,2.9c0.1-0.1,0.3-0.1,0.4-0.1
			c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H52.3z"/>
		<path className="cl-1" d="M55.6,5.4c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4V3.7c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1L56,5.1L55.6,5.4z M55.2,4c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V4z"/>
		<path className="cl-1" d="M58.9,5.2c-0.1,0-0.5,0.1-0.8,0.2V5l-0.3,0.2c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0-1-0.4-1-1.2
			c0-0.8,0.7-1.4,1.4-1.4c0.1,0,0.3,0,0.4,0.1V2.1c0-0.4,0-0.4-0.4-0.4V1.6c0.3,0,0.6-0.1,0.8-0.2v3.3c0,0.3,0,0.3,0.2,0.3l0.2,0
			V5.2z M58.1,3.3C58,3.1,57.7,3,57.5,3c-0.3,0-0.8,0.2-0.8,1c0,0.7,0.4,1,0.8,1c0.2,0,0.4-0.1,0.6-0.2V3.3z"/>
		<path className="cl-1" d="M61.2,4.9c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L61.2,4.9z
			 M60.3,3c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C60.8,3.3,60.6,3,60.3,3L60.3,3z"/>
		<path className="cl-1" d="M64.1,2.9c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1,0.5,1,1.1c0,0.9-0.7,1.4-1.4,1.5c-0.2,0-0.3-0.1-0.4-0.1V6
			c0,0.4,0,0.5,0.5,0.5v0.2h-1.3V6.5c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.4,0-0.4-0.4-0.4V3c0.2,0,0.5-0.1,0.8-0.2v0.4L64.1,2.9z
			 M63.5,4.9c0.1,0.1,0.3,0.2,0.5,0.2c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-0.9-0.8-0.9c-0.2,0-0.4,0.1-0.6,0.3V4.9z"/>
		<path className="cl-1" d="M67,2.8c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2C65.8,3.2,66.5,2.8,67,2.8
			L67,2.8z M66.3,4c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1C67.7,3.5,67.5,3,67,3C66.6,3,66.3,3.3,66.3,4z"/>
		<path className="cl-1" d="M70.1,3.5C70,3.2,69.8,3,69.5,3c-0.2,0-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.5,0.5C70,4,70.3,4.2,70.3,4.6
			c0,0.5-0.5,0.8-0.9,0.8c-0.3,0-0.6-0.1-0.6-0.2c0-0.1-0.1-0.5-0.1-0.7l0.2,0c0.1,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.2,0.4-0.4
			c0-0.2-0.2-0.4-0.4-0.5c-0.4-0.2-0.7-0.3-0.7-0.8c0-0.4,0.3-0.7,0.8-0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0.1,0.6L70.1,3.5z"
			/>
		<path className="cl-1" d="M72.1,3.5C72,3.2,71.8,3,71.6,3c-0.2,0-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.5,0.5c0.4,0.2,0.7,0.4,0.7,0.7
			c0,0.5-0.5,0.8-0.9,0.8c-0.3,0-0.6-0.1-0.6-0.2c0-0.1-0.1-0.5-0.1-0.7l0.2,0c0.1,0.4,0.3,0.7,0.7,0.7C71.8,5.2,72,5,72,4.8
			c0-0.2-0.2-0.4-0.4-0.5c-0.4-0.2-0.7-0.3-0.7-0.8c0-0.4,0.3-0.7,0.8-0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0.1,0.6L72.1,3.5z"
			/>
		<path className="cl-1" d="M72.7,5.3V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.3,0-0.4-0.4-0.4V3c0.3-0.1,0.5-0.1,0.8-0.2v1.9
			c0,0.4,0,0.4,0.4,0.4v0.2H72.7z M73,2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3C73.2,2.3,73,2.2,73,2z"
			/>
		<path className="cl-1" d="M75.3,2.9c0.1,0,0.2,0,0.2,0c0.6,0,1.1,0.5,1.1,1.2c0,0.8-0.7,1.4-1.4,1.4c-0.2,0-0.5-0.1-0.8-0.2V2.1
			c0-0.3,0-0.4-0.4-0.4V1.6c0.2,0,0.6-0.1,0.8-0.2v1.7L75.3,2.9z M74.9,4.7c0,0.1,0,0.1,0,0.2c0,0.1,0.2,0.3,0.5,0.3
			c0.5,0,0.8-0.4,0.8-1c0-0.6-0.4-1-0.9-1c-0.2,0-0.4,0.1-0.5,0.1V4.7z"/>
		<path className="cl-1" d="M76.9,5.3V5.2c0.4,0,0.4-0.1,0.4-0.4V2.1c0-0.4,0-0.4-0.4-0.4V1.6c0.3,0,0.6-0.1,0.8-0.2v3.4
			c0,0.4,0,0.4,0.4,0.4v0.2H76.9z"/>
		<path className="cl-1" d="M80.4,4.9c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1C79,3,79.3,2.8,79.6,2.8h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L80.4,4.9z
			 M79.5,3c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C80,3.3,79.8,3,79.5,3L79.5,3z"/>
		<path className="cl-1" d="M82,5.3V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.3,0-0.4-0.4-0.4V3c0.3-0.1,0.5-0.1,0.8-0.2v1.9
			c0,0.4,0,0.4,0.4,0.4v0.2H82z M82.3,2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3
			C82.4,2.3,82.3,2.2,82.3,2z"/>
		<path className="cl-1" d="M85.1,5.3V5.2c0.3,0,0.4-0.1,0.4-0.5V3.8c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.4,0-0.4-0.3-0.4V3c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H85.1z"/>
		<path className="cl-1" d="M89,2.9c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1,0.5,1,1.1c0,0.9-0.7,1.4-1.4,1.5c-0.2,0-0.3-0.1-0.4-0.1V6
			c0,0.4,0,0.5,0.5,0.5v0.2h-1.3V6.5c0.4,0,0.4-0.1,0.4-0.4V3.6c0-0.4,0-0.4-0.4-0.4V3c0.2,0,0.5-0.1,0.8-0.2v0.4L89,2.9z M88.5,4.9
			C88.6,5,88.8,5.1,89,5.1c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-0.9-0.8-0.9c-0.2,0-0.4,0.1-0.6,0.3V4.9z"/>
		<path className="cl-1" d="M92.5,5.4c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6C91.6,4,92,3.9,92.1,3.8V3.7c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L92.5,5.4z M92.1,4C92,4,91.7,4.2,91.5,4.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V4z"/>
		<path className="cl-1" d="M93.9,3.4c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3V5.2c0.4,0,0.4-0.1,0.4-0.4V3.6
			c0-0.4,0-0.4-0.4-0.4V3c0.3,0,0.5-0.1,0.7-0.2L93.9,3.4L93.9,3.4z"/>
		<path className="cl-1" d="M96.3,5.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7V3.1h-0.4l0-0.1l0.2-0.2h0.3V2.5l0.3-0.4l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L96.3,5.3z"/>
		<path className="cl-1" d="M99.2,2.9c0.1,0,0.2,0,0.2,0c0.6,0,1.1,0.5,1.1,1.2c0,0.8-0.7,1.4-1.4,1.4c-0.2,0-0.5-0.1-0.8-0.2V2.1
			c0-0.3,0-0.4-0.4-0.4V1.6c0.2,0,0.6-0.1,0.8-0.2v1.7L99.2,2.9z M98.7,4.7c0,0.1,0,0.1,0,0.2c0,0.1,0.2,0.3,0.5,0.3
			c0.5,0,0.8-0.4,0.8-1c0-0.6-0.4-1-0.9-1c-0.2,0-0.4,0.1-0.5,0.1V4.7z"/>
		<path className="cl-1" d="M103.3,3c-0.3,0-0.4,0.1-0.5,0.4c-0.1,0.3-0.3,0.7-0.7,1.7c-0.4,0.9-0.5,1.2-0.6,1.5c0,0.1-0.1,0.1-0.2,0.1
			c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.3,0.2-0.4c0-0.1,0-0.2,0-0.3
			c-0.2-0.6-0.4-1.2-0.6-1.7C101,3.2,101,3.1,100.7,3V2.9h1.1V3c-0.3,0-0.3,0.1-0.2,0.3l0.5,1.4c0.2-0.4,0.4-1,0.5-1.4
			c0.1-0.2,0-0.3-0.3-0.3V2.9h1V3z"/>
		<path className="cl-1" d="M9.6,12.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7v-1.6H8.4l0-0.1l0.2-0.2h0.3V9.7l0.3-0.4l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2H9.2v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L9.6,12.5z"/>
		<path className="cl-1" d="M11.7,12.5v-0.2c0.3,0,0.4-0.1,0.4-0.4V11c0-0.5-0.2-0.7-0.6-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.3,0,0.4-0.1,0.4-0.4V9.3c0-0.3,0-0.4-0.4-0.4V8.8c0.3,0,0.6-0.1,0.8-0.2v1.8
			c0.2-0.2,0.5-0.4,0.8-0.4c0.5,0,0.8,0.3,0.8,1v1c0,0.4,0,0.4,0.4,0.4v0.2H11.7z"/>
		<path className="cl-1" d="M15.2,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L15.2,12.1z
			 M14.3,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C14.8,10.5,14.6,10.2,14.3,10.2L14.3,10.2z"/>
		<path className="cl-1" d="M20.4,11c-0.3,0.1-0.4,0.1-0.4,0.4v0.5c0,0.2,0,0.4,0,0.5c-0.4,0.1-0.8,0.2-1.2,0.2c-1.2,0-2-0.8-2-1.9
			c0-1.3,1-1.9,2.1-1.9c0.5,0,0.9,0.1,1.1,0.2c0,0.2,0,0.5,0.1,0.9l-0.2,0C19.8,9.2,19.4,9,18.9,9c-0.9,0-1.5,0.6-1.5,1.6
			c0,0.8,0.5,1.7,1.6,1.7c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.1,0.2-0.4v-0.4c0-0.4,0-0.4-0.6-0.5v-0.2h1.4V11z"/>
		<path className="cl-1" d="M22,10c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2C20.8,10.4,21.5,10,22,10L22,10z
			 M21.3,11.2c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1c0-0.6-0.3-1.2-0.8-1.2C21.6,10.2,21.3,10.5,21.3,11.2z"/>
		<path className="cl-1" d="M26,10.2c-0.3,0-0.3,0.1-0.5,0.4c-0.2,0.4-0.5,1.1-0.8,1.9h-0.2c-0.2-0.7-0.5-1.3-0.8-1.9
			c-0.1-0.3-0.2-0.4-0.5-0.4v-0.2h1.1v0.2c-0.3,0-0.3,0.1-0.2,0.3c0.1,0.4,0.4,1,0.5,1.4c0.2-0.5,0.4-1,0.5-1.3
			c0.1-0.3,0.1-0.3-0.3-0.4v-0.2h1V10.2z"/>
		<path className="cl-1" d="M28.1,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L28.1,12.1z
			 M27.2,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C27.7,10.5,27.5,10.2,27.2,10.2L27.2,10.2z"/>
		<path className="cl-1" d="M29.3,10.6c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1
			c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2L29.3,10.6L29.3,10.6z"/>
		<path className="cl-1" d="M32.2,12.5v-0.2c0.3,0,0.4-0.1,0.4-0.5V11c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H32.2z"/>
		<path className="cl-1" d="M36.7,12.5v-0.2c0.3,0,0.4-0.1,0.4-0.4V11c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.4,0.1-0.7,0.3c0,0.1,0,0.1,0,0.2
			v1.1c0,0.4,0.1,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4V11c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.2-0.7,0.3v1.3
			c0,0.4,0,0.4,0.3,0.4v0.2h-1.1v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.4-0.4v-0.1c0.2,0,0.5-0.1,0.7-0.2v0.5
			c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.5,0.2,0.6,0.5c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.3-0.1,0.4-0.1
			c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H36.7z"/>
		<path className="cl-1" d="M40.2,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L40.2,12.1z
			 M39.3,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C39.8,10.5,39.6,10.2,39.3,10.2L39.3,10.2z"/>
		<path className="cl-1" d="M42.2,12.5v-0.2c0.3,0,0.4-0.1,0.4-0.5V11c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H42.2z"/>
		<path className="cl-1" d="M44.6,12.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7v-1.6h-0.4l0-0.1l0.2-0.2h0.3V9.7l0.3-0.4l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L44.6,12.5z"/>
		<path className="cl-1" d="M47.7,10c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2C46.5,10.4,47.2,10,47.7,10
			L47.7,10z M46.9,11.2c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1c0-0.6-0.3-1.2-0.8-1.2C47.3,10.2,46.9,10.5,46.9,11.2z"/>
		<path className="cl-1" d="M49.6,10.1L49.6,10.1c0-0.3,0-0.4,0.1-0.6c0-0.1,0.1-0.4,0.4-0.7c0.2-0.2,0.4-0.3,0.6-0.3
			c0.2,0,0.4,0.1,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.2,0.1-0.3,0.2
			C50.1,9.1,50,9.4,50,9.9v0.2h0.7c0,0,0,0.2-0.1,0.2H50v1.6c0,0.4,0,0.4,0.5,0.5v0.1h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.6h-0.4
			l0-0.1l0.1-0.2H49.6z"/>
		<path className="cl-1" d="M55.4,11.6c-0.1,0.3-0.2,0.7-0.3,0.9c-0.2,0-0.6,0.2-1,0.2c-1.3,0-1.9-1-1.9-1.8c0-1.2,0.9-1.9,2.1-1.9
			c0.4,0,0.8,0.1,1,0.2c0,0.3,0.1,0.5,0.1,0.8l-0.2,0C55,9.3,54.7,9,54.1,9c-0.9,0-1.4,0.8-1.4,1.6c0,1,0.6,1.7,1.5,1.7
			c0.6,0,0.8-0.3,1.1-0.9L55.4,11.6z"/>
		<path className="cl-1" d="M57.5,12.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L57.5,12.6z M57.2,11.2c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V11.2z"/>
		<path className="cl-1" d="M59.8,12.5v-0.2c0.3,0,0.4-0.1,0.4-0.5V11c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H59.8z"/>
		<path className="cl-1" d="M63,12.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L63,12.6z M62.6,11.2c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V11.2z"/>
		<path className="cl-1" d="M66.4,12.4c-0.1,0-0.5,0.1-0.8,0.2v-0.4l-0.3,0.2c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0-1-0.4-1-1.2
			c0-0.8,0.7-1.4,1.4-1.4c0.1,0,0.3,0,0.4,0.1V9.3c0-0.4,0-0.4-0.4-0.4V8.8c0.3,0,0.6-0.1,0.8-0.2v3.3c0,0.3,0,0.3,0.2,0.3l0.2,0
			V12.4z M65.5,10.5c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.8,0.2-0.8,1c0,0.7,0.4,1,0.8,1c0.2,0,0.4-0.1,0.6-0.2V10.5z"/>
		<path className="cl-1" d="M68.4,12.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L68.4,12.6z M68,11.2c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V11.2z"/>
		<path className="cl-1" d="M69.5,12.6c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
			C69.9,12.5,69.7,12.6,69.5,12.6L69.5,12.6z"/>
		<path className="cl-1" d="M74.9,11.6c-0.1,0.3-0.2,0.7-0.3,0.9c-0.2,0-0.6,0.2-1,0.2c-1.3,0-1.9-1-1.9-1.8c0-1.2,0.9-1.9,2.1-1.9
			c0.4,0,0.8,0.1,1,0.2c0,0.3,0.1,0.5,0.1,0.8l-0.2,0C74.5,9.3,74.2,9,73.6,9c-0.9,0-1.4,0.8-1.4,1.6c0,1,0.6,1.7,1.5,1.7
			c0.6,0,0.8-0.3,1.1-0.9L74.9,11.6z"/>
		<path className="cl-1" d="M77.4,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L77.4,12.1z
			 M76.4,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C76.9,10.5,76.7,10.2,76.4,10.2L76.4,10.2z"/>
		<path className="cl-1" d="M80.2,10.1c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1,0.5,1,1.1c0,0.9-0.7,1.4-1.4,1.5c-0.2,0-0.3-0.1-0.4-0.1v0.7
			c0,0.4,0,0.5,0.5,0.5v0.2h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4v-2.5c0-0.4,0-0.4-0.4-0.4v-0.2c0.2,0,0.5-0.1,0.8-0.2v0.4L80.2,10.1z
			 M79.7,12.1c0.1,0.1,0.3,0.2,0.5,0.2c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-0.9-0.8-0.9c-0.2,0-0.4,0.1-0.6,0.3V12.1z"/>
		<path className="cl-1" d="M82.6,10.6c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1
			c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2L82.6,10.6L82.6,10.6z"/>
		<path className="cl-1" d="M85,10c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2C83.8,10.4,84.5,10,85,10L85,10z
			 M84.3,11.2c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1c0-0.6-0.3-1.2-0.8-1.2C84.6,10.2,84.3,10.5,84.3,11.2z"/>
		<path className="cl-1" d="M86.6,10.2c0.3,0,0.6-0.1,0.8-0.2v1.9c0,0.4,0,0.6-0.1,0.9c-0.2,0.6-0.5,1-0.6,1.1c0,0-0.1,0-0.1,0
			c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0.1-0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.2-0.4c0-0.2,0.1-0.5,0.1-1.1v-1.1
			c0-0.4,0-0.4-0.4-0.4V10.2z M86.9,9.2C86.9,9.1,87,9,87.1,9c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3
			C87,9.5,86.9,9.4,86.9,9.2z"/>
		<path className="cl-1" d="M90,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L90,12.1z
			 M89.1,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C89.6,10.5,89.4,10.2,89.1,10.2L89.1,10.2z"/>
		<path className="cl-1" d="M91.5,12.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7v-1.6h-0.4l0-0.1l0.2-0.2h0.3V9.7L91,9.3l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L91.5,12.5z"/>
		<path className="cl-1" d="M95.1,12.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L95.1,12.6z M94.7,11.2c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V11.2z"/>
		<path className="cl-1" d="M99,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L99,12.1z
			 M98,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C98.6,10.5,98.4,10.2,98,10.2L98,10.2z M97.8,9.7
			c0.1-0.2,0.4-0.8,0.4-0.9c0,0,0.4-0.1,0.4-0.1l0,0.1l-0.7,1L97.8,9.7z"/>
		<path className="cl-1" d="M100.5,12.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7v-1.6h-0.4l0-0.1l0.2-0.2h0.3V9.7l0.3-0.4l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L100.5,12.5z"/>
		<path className="cl-1" d="M103.1,12.1c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L103.1,12.1z
			 M102.1,10.2c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C102.6,10.5,102.4,10.2,102.1,10.2L102.1,10.2z
			 M101.9,9.7c0.1-0.2,0.4-0.8,0.4-0.9c0,0,0.4-0.1,0.4-0.1l0,0.1l-0.7,1L101.9,9.7z"/>
		<path className="cl-1" d="M31.2,17.8c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4V18
			c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2L31.2,17.8L31.2,17.8z"/>
		<path className="cl-1" d="M34.4,19.3c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L34.4,19.3z
			 M33.4,17.4c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C33.9,17.7,33.7,17.4,33.4,17.4L33.4,17.4z"/>
		<path className="cl-1" d="M36.3,19.7v-0.2c0.3,0,0.4-0.1,0.4-0.5v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4V18c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H36.3z"/>
		<path className="cl-1" d="M40.5,19.6c-0.1,0-0.5,0.1-0.8,0.2v-0.4l-0.3,0.2c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0-1-0.4-1-1.2
			c0-0.8,0.7-1.4,1.4-1.4c0.1,0,0.3,0,0.4,0.1v-0.8c0-0.4,0-0.4-0.4-0.4V16c0.3,0,0.6-0.1,0.8-0.2v3.3c0,0.3,0,0.3,0.2,0.3l0.2,0
			V19.6z M39.6,17.7c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.8,0.2-0.8,1c0,0.7,0.4,1,0.8,1c0.2,0,0.4-0.1,0.6-0.2V17.7z"/>
		<path className="cl-1" d="M43.4,19.6c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1l0,0v-0.4c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.2,0.1-0.3,0.1-0.4,0.1c-0.4,0-0.7-0.2-0.7-0.8v-1.1c0-0.3,0-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4-0.1
			c0,0.2,0,0.5,0,0.8v0.7c0,0.5,0.3,0.6,0.5,0.6c0.2,0,0.4-0.1,0.6-0.3v-1.3c0-0.3-0.1-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.3,0,0.5,0
			c0.2,0,0.3,0,0.3-0.1v1.9c0,0.3,0,0.3,0.3,0.3l0.2,0V19.6z"/>
		<path className="cl-1" d="M46.1,17.3c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1,0.5,1,1.1c0,0.9-0.7,1.4-1.4,1.5c-0.2,0-0.3-0.1-0.4-0.1v0.7
			c0,0.4,0,0.5,0.5,0.5V21h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4V18c0-0.4,0-0.4-0.4-0.4v-0.2c0.2,0,0.5-0.1,0.8-0.2v0.4L46.1,17.3z
			 M45.6,19.3c0.1,0.1,0.3,0.2,0.5,0.2c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-0.9-0.8-0.9c-0.2,0-0.4,0.1-0.6,0.3V19.3z"/>
		<path className="cl-1" d="M49,17.2c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2C47.8,17.6,48.6,17.2,49,17.2
			L49,17.2z M48.3,18.4c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1c0-0.6-0.3-1.2-0.8-1.2C48.6,17.4,48.3,17.7,48.3,18.4z"/>
		<path className="cl-1" d="M52.1,17.9c-0.1-0.3-0.3-0.6-0.6-0.6c-0.2,0-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.5,0.5c0.4,0.2,0.7,0.4,0.7,0.8
			c0,0.5-0.5,0.8-0.9,0.8c-0.3,0-0.6-0.1-0.6-0.2c0-0.1-0.1-0.5-0.1-0.7l0.2,0c0.1,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.2,0.4-0.4
			s-0.2-0.4-0.4-0.5c-0.4-0.2-0.7-0.3-0.7-0.8c0-0.4,0.3-0.7,0.8-0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0.1,0.6L52.1,17.9z"/>
		<path className="cl-1" d="M54.1,17.9c-0.1-0.3-0.3-0.6-0.6-0.6c-0.2,0-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.5,0.5c0.4,0.2,0.7,0.4,0.7,0.8
			c0,0.5-0.5,0.8-0.9,0.8c-0.3,0-0.6-0.1-0.6-0.2c0-0.1-0.1-0.5-0.1-0.7l0.2,0c0.1,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.2,0.4-0.4
			s-0.2-0.4-0.4-0.5c-0.4-0.2-0.7-0.3-0.7-0.8c0-0.4,0.3-0.7,0.8-0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0.1,0.6L54.1,17.9z"/>
		<path className="cl-1" d="M54.8,19.7v-0.2c0.4,0,0.4-0.1,0.4-0.4V18c0-0.3,0-0.4-0.4-0.4v-0.1c0.3-0.1,0.5-0.1,0.8-0.2v1.9
			c0,0.4,0,0.4,0.4,0.4v0.2H54.8z M55.1,16.4c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3
			C55.2,16.7,55.1,16.6,55.1,16.4z"/>
		<path className="cl-1" d="M57.4,17.3c0.1,0,0.2,0,0.2,0c0.6,0,1.1,0.5,1.1,1.2c0,0.8-0.7,1.4-1.4,1.4c-0.2,0-0.5-0.1-0.8-0.2v-3.1
			c0-0.3,0-0.4-0.4-0.4V16c0.2,0,0.6-0.1,0.8-0.2v1.7L57.4,17.3z M56.9,19.1c0,0.1,0,0.1,0,0.2c0,0.1,0.2,0.3,0.5,0.3
			c0.5,0,0.8-0.4,0.8-1c0-0.6-0.4-1-0.9-1c-0.2,0-0.4,0.1-0.5,0.1V19.1z"/>
		<path className="cl-1" d="M58.9,19.7v-0.2c0.4,0,0.4-0.1,0.4-0.4v-2.6c0-0.4,0-0.4-0.4-0.4V16c0.3,0,0.6-0.1,0.8-0.2v3.4
			c0,0.4,0,0.4,0.4,0.4v0.2H58.9z"/>
		<path className="cl-1" d="M62.5,19.3c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L62.5,19.3z
			 M61.5,17.4c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C62,17.7,61.8,17.4,61.5,17.4L61.5,17.4z"/>
		<path className="cl-1" d="M66.1,19.3c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L66.1,19.3z
			 M65.1,17.4c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C65.6,17.7,65.4,17.4,65.1,17.4L65.1,17.4z"/>
		<path className="cl-1" d="M68,19.7v-0.2c0.3,0,0.4-0.1,0.4-0.5v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4V18c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H68z"/>
		<path className="cl-1" d="M72,17.3c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1,0.5,1,1.1c0,0.9-0.7,1.4-1.4,1.5c-0.2,0-0.3-0.1-0.4-0.1v0.7
			c0,0.4,0,0.5,0.5,0.5V21h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4V18c0-0.4,0-0.4-0.4-0.4v-0.2c0.2,0,0.5-0.1,0.8-0.2v0.4L72,17.3z
			 M71.4,19.3c0.1,0.1,0.3,0.2,0.5,0.2c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-0.9-0.8-0.9c-0.2,0-0.4,0.1-0.6,0.3V19.3z"/>
		<path className="cl-1" d="M75.4,19.8c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L75.4,19.8z M75,18.4c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V18.4z"/>
		<path className="cl-1" d="M76.8,17.8c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2H76v-0.2c0.4,0,0.4-0.1,0.4-0.4V18
			c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2L76.8,17.8L76.8,17.8z"/>
		<path className="cl-1" d="M79.3,19.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.7v-1.6h-0.4l0-0.1l0.2-0.2h0.3v-0.4l0.3-0.4l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L79.3,19.7z"/>
		<path className="cl-1" d="M79.9,19.7v-0.2c0.4,0,0.4-0.1,0.4-0.4V18c0-0.3,0-0.4-0.4-0.4v-0.1c0.3-0.1,0.5-0.1,0.8-0.2v1.9
			c0,0.4,0,0.4,0.4,0.4v0.2H79.9z M80.2,16.4c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3
			C80.3,16.7,80.2,16.6,80.2,16.4z"/>
		<path className="cl-1" d="M83.4,19.3c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L83.4,19.3z
			 M82.4,17.4c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C83,17.7,82.8,17.4,82.4,17.4L82.4,17.4z"/>
		<path className="cl-1" d="M87.4,17.3c0,0.1-0.2,0.2-0.3,0.3l-0.3,0c0.1,0.1,0.2,0.3,0.2,0.5c0,0.6-0.5,0.9-1,0.9c-0.1,0-0.2,0-0.3,0
			c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1,0.1,0.2,0.3,0.2c0.2,0,0.4,0,0.6,0c0.4,0,0.8,0.1,0.8,0.7c0,0.6-0.6,1.1-1.4,1.1
			c-0.6,0-1-0.4-1-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2-0.1-0.2
			c0.2-0.1,0.4-0.2,0.5-0.3c-0.2-0.1-0.5-0.3-0.5-0.7c0-0.6,0.6-0.9,1-0.9h0c0.2,0,0.4,0,0.5,0.1C86.9,17.3,87.1,17.3,87.4,17.3
			L87.4,17.3z M86.2,19.7c-0.3,0-0.4,0-0.5,0.1c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3,0.3,0.5,0.7,0.5c0.5,0,0.8-0.3,0.8-0.6
			c0-0.2-0.1-0.3-0.2-0.4C86.6,19.8,86.4,19.7,86.2,19.7L86.2,19.7z M86,17.4c-0.3,0-0.5,0.2-0.5,0.7c0,0.4,0.2,0.7,0.5,0.7
			c0.3,0,0.5-0.2,0.5-0.7C86.6,17.7,86.3,17.4,86,17.4L86,17.4z"/>
		<path className="cl-1" d="M88.4,17.8c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4V18
			c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2L88.4,17.8L88.4,17.8z"/>
		<path className="cl-1" d="M91.4,19.8c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L91.4,19.8z M91.1,17c-0.2-0.2-0.3-0.4-0.5-0.5
			c-0.1,0.2-0.3,0.4-0.5,0.5L90,16.9c0.2-0.3,0.4-0.6,0.6-0.9h0.1c0.2,0.3,0.3,0.6,0.6,0.9L91.1,17z M91.1,18.4
			c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V18.4z"/>
		<path className="cl-1" d="M94.2,19.2c-0.2,0.3-0.6,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.2-0.8,0.6-1.1c0.3-0.2,0.6-0.3,0.8-0.3
			h0c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0c-0.2-0.2-0.4-0.2-0.6-0.2
			c-0.4,0-0.7,0.3-0.7,0.9c0,0.8,0.5,1,0.8,1c0.2,0,0.4-0.1,0.7-0.3L94.2,19.2z"/>
		<path className="cl-1" d="M96.5,19.3c-0.4,0.4-0.7,0.5-0.9,0.5c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L96.5,19.3z
			 M95.6,17.4c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C96.1,17.7,95.9,17.4,95.6,17.4L95.6,17.4z"/>
		<path className="cl-1" d="M99.9,19.8c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L99.9,19.8z M99.5,18.4c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V18.4z"/>
		<path className="cl-1" d="M103.2,19.6c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1l0,0v-0.4c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.2,0.1-0.3,0.1-0.4,0.1c-0.4,0-0.7-0.2-0.7-0.8v-1.1c0-0.3,0-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4-0.1
			c0,0.2,0,0.5,0,0.8v0.7c0,0.5,0.3,0.6,0.5,0.6c0.2,0,0.4-0.1,0.6-0.3v-1.3c0-0.3-0.1-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.3,0,0.5,0
			c0.2,0,0.3,0,0.3-0.1v1.9c0,0.3,0,0.3,0.3,0.3l0.2,0V19.6z"/>
		<path className="cl-1" d="M46.9,24.5c0,0.1-0.2,0.2-0.3,0.3l-0.3,0c0.1,0.1,0.2,0.3,0.2,0.5c0,0.6-0.5,0.9-1,0.9c-0.1,0-0.2,0-0.3,0
			c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1,0.1,0.2,0.3,0.2c0.2,0,0.4,0,0.6,0c0.4,0,0.8,0.1,0.8,0.7c0,0.6-0.6,1.1-1.4,1.1
			c-0.6,0-1-0.4-1-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2-0.1-0.2
			c0.2-0.1,0.4-0.2,0.5-0.3c-0.2-0.1-0.5-0.3-0.5-0.7c0-0.6,0.6-0.9,1-0.9h0c0.2,0,0.4,0,0.5,0.1C46.4,24.5,46.7,24.5,46.9,24.5
			L46.9,24.5z M45.7,26.9c-0.3,0-0.4,0-0.5,0.1c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3,0.3,0.5,0.7,0.5c0.5,0,0.8-0.3,0.8-0.6
			c0-0.2-0.1-0.3-0.2-0.4C46.1,27,45.9,26.9,45.7,26.9L45.7,26.9z M45.5,24.6c-0.3,0-0.5,0.2-0.5,0.7c0,0.4,0.2,0.7,0.5,0.7
			c0.3,0,0.5-0.2,0.5-0.7C46.1,24.9,45.9,24.6,45.5,24.6L45.5,24.6z"/>
		<path className="cl-1" d="M48.3,24.4c0.7,0,1.2,0.5,1.2,1.2c0,0.9-0.7,1.3-1.2,1.3c-0.8,0-1.2-0.6-1.2-1.2
			C47.1,24.8,47.8,24.4,48.3,24.4L48.3,24.4z M47.6,25.6c0,0.7,0.3,1.2,0.8,1.2c0.4,0,0.7-0.3,0.7-1c0-0.6-0.3-1.2-0.8-1.2
			C47.9,24.6,47.6,24.9,47.6,25.6z"/>
		<path className="cl-1" d="M52.6,26.8c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1l0,0v-0.4c-0.1,0.1-0.3,0.2-0.4,0.3
			C51.1,26.9,51,27,50.9,27c-0.4,0-0.7-0.2-0.7-0.8v-1.1c0-0.3,0-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4-0.1
			c0,0.2,0,0.5,0,0.8V26c0,0.5,0.3,0.6,0.5,0.6c0.2,0,0.4-0.1,0.6-0.3v-1.3c0-0.3-0.1-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.3,0,0.5,0
			c0.2,0,0.3,0,0.3-0.1v1.9c0,0.3,0,0.3,0.3,0.3l0.2,0V26.8z"/>
		<path className="cl-1" d="M55.3,24.6c-0.3,0-0.3,0.1-0.5,0.4c-0.2,0.4-0.5,1.1-0.8,1.9h-0.2c-0.2-0.7-0.5-1.3-0.8-1.9
			c-0.1-0.3-0.2-0.4-0.5-0.4v-0.2h1.1v0.2c-0.3,0-0.3,0.1-0.2,0.3c0.1,0.4,0.4,1,0.5,1.4c0.2-0.5,0.4-1,0.5-1.3
			c0.1-0.3,0.1-0.3-0.3-0.4v-0.2h1V24.6z"/>
		<path className="cl-1" d="M57.3,26.5C57,26.9,56.6,27,56.4,27c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L57.3,26.5z
			 M56.4,24.6c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C56.9,24.9,56.7,24.6,56.4,24.6L56.4,24.6z"/>
		<path className="cl-1" d="M58.5,25c0.2-0.3,0.4-0.6,0.7-0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0
			c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.5,0.5v1c0,0.4,0,0.4,0.5,0.4v0.2h-1.3v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1
			c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2L58.5,25L58.5,25z"/>
		<path className="cl-1" d="M61.4,26.9v-0.2c0.3,0,0.4-0.1,0.4-0.5v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H61.4z"/>
		<path className="cl-1" d="M64.9,26.5C64.5,26.9,64.2,27,64,27c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L64.9,26.5z
			 M64,24.6c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C64.5,24.9,64.3,24.6,64,24.6L64,24.6z"/>
		<path className="cl-1" d="M68.3,26.9v-0.2c0.3,0,0.4-0.1,0.4-0.4v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.4,0.1-0.7,0.3c0,0.1,0,0.1,0,0.2
			v1.1c0,0.4,0.1,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.2-0.7,0.3v1.3
			c0,0.4,0,0.4,0.3,0.4v0.2h-1.1v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.4-0.4v-0.1c0.2,0,0.5-0.1,0.7-0.2v0.5
			c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.5,0.2,0.6,0.5c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.3-0.1,0.4-0.1
			c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H68.3z"/>
		<path className="cl-1" d="M71.9,26.5C71.5,26.9,71.1,27,71,27c-0.7,0-1.1-0.6-1.1-1.2c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4h0
			c0.5,0,0.8,0.4,0.8,0.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.8,0.1-1.5,0.1c0,0.8,0.5,1.1,0.8,1.1c0.2,0,0.4-0.1,0.6-0.3L71.9,26.5z
			 M70.9,24.6c-0.3,0-0.5,0.2-0.6,0.7c0.3,0,0.6,0,1,0c0.1,0,0.1,0,0.1-0.1C71.4,24.9,71.2,24.6,70.9,24.6L70.9,24.6z"/>
		<path className="cl-1" d="M73.8,26.9v-0.2c0.3,0,0.4-0.1,0.4-0.5v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H73.8z"/>
		<path className="cl-1" d="M76.3,26.9C76.2,27,76.1,27,76,27c-0.3,0-0.5-0.2-0.5-0.7v-1.6h-0.4l0-0.1l0.2-0.2h0.3v-0.4l0.3-0.4l0.1,0
			v0.7h0.6c0.1,0.1,0,0.2,0,0.2h-0.6v1.4c0,0.4,0.2,0.5,0.3,0.5c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1L76.3,26.9z"/>
		<path className="cl-1" d="M80.8,26.8c-0.1,0-0.5,0.1-0.8,0.2v-0.4l-0.3,0.2c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0-1-0.4-1-1.2
			c0-0.8,0.7-1.4,1.4-1.4c0.1,0,0.3,0,0.4,0.1v-0.8c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.6-0.1,0.8-0.2v3.3c0,0.3,0,0.3,0.2,0.3l0.2,0
			V26.8z M79.9,24.9c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.8,0.2-0.8,1c0,0.7,0.4,1,0.8,1c0.2,0,0.4-0.1,0.6-0.2V24.9z"/>
		<path className="cl-1" d="M83.6,26.8c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1l0,0v-0.4c-0.1,0.1-0.3,0.2-0.4,0.3
			C82.2,26.9,82.1,27,82,27c-0.4,0-0.7-0.2-0.7-0.8v-1.1c0-0.3,0-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4-0.1
			c0,0.2,0,0.5,0,0.8V26c0,0.5,0.3,0.6,0.5,0.6c0.2,0,0.4-0.1,0.6-0.3v-1.3c0-0.3-0.1-0.3-0.2-0.4l-0.2,0v-0.1c0.1,0,0.3,0,0.5,0
			c0.2,0,0.3,0,0.3-0.1v1.9c0,0.3,0,0.3,0.3,0.3l0.2,0V26.8z"/>
		<path className="cl-1" d="M88.5,26c-0.1,0.3-0.2,0.7-0.3,0.9c-0.2,0-0.6,0.2-1,0.2c-1.3,0-1.9-1-1.9-1.8c0-1.2,0.9-1.9,2.1-1.9
			c0.4,0,0.8,0.1,1,0.2c0,0.3,0.1,0.5,0.1,0.8l-0.2,0c-0.1-0.6-0.4-0.8-1.1-0.8c-0.9,0-1.4,0.8-1.4,1.6c0,1,0.6,1.7,1.5,1.7
			c0.6,0,0.8-0.3,1.1-0.9L88.5,26z"/>
		<path className="cl-1" d="M90.6,27c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3C90,26.8,89.7,27,89.5,27c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L90.6,27z M90.2,25.6c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V25.6z"/>
		<path className="cl-1" d="M92.8,26.9v-0.2c0.3,0,0.4-0.1,0.4-0.5v-0.9c0-0.4-0.1-0.7-0.5-0.7c-0.2,0-0.5,0.1-0.6,0.3v1.3
			c0,0.4,0,0.4,0.4,0.4v0.2h-1.2v-0.2c0.4,0,0.4-0.1,0.4-0.4v-1.1c0-0.4,0-0.4-0.3-0.4v-0.1c0.3,0,0.5-0.1,0.7-0.2v0.4
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.8v1.1c0,0.4,0,0.4,0.4,0.4v0.2H92.8z"/>
		<path className="cl-1" d="M96.1,27c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3C95.4,26.8,95.2,27,95,27c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L96.1,27z M95.7,25.6c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V25.6z"/>
		<path className="cl-1" d="M99.4,26.8c-0.1,0-0.5,0.1-0.8,0.2v-0.4l-0.3,0.2C98,26.9,97.9,27,97.8,27c-0.4,0-1-0.4-1-1.2
			c0-0.8,0.7-1.4,1.4-1.4c0.1,0,0.3,0,0.4,0.1v-0.8c0-0.4,0-0.4-0.4-0.4v-0.1c0.3,0,0.6-0.1,0.8-0.2v3.3c0,0.3,0,0.3,0.2,0.3l0.2,0
			V26.8z M98.6,24.9c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.8,0.2-0.8,1c0,0.7,0.4,1,0.8,1c0.2,0,0.4-0.1,0.6-0.2V24.9z"/>
		<path className="cl-1" d="M101.5,27c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.1-0.5,0.5-0.6c0.3-0.1,0.7-0.3,0.9-0.4v-0.1c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.5-0.3,0.8-0.4c0.2,0,0.3,0,0.4,0.2
			c0.2,0.2,0.2,0.4,0.2,0.7v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.1,0.2L101.5,27z M101.1,25.6c-0.1,0.1-0.4,0.2-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.4-0.1,0.5-0.2V25.6z"/>
		<path className="cl-1" d="M102.6,27c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3C102.9,26.9,102.8,27,102.6,27
			L102.6,27z"/>
	</g>
</g>
</svg>

	)
}

export default CanadaLogo
