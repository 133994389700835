import React from "react"
import socialmediaData from '../data/socialmedia-data';
import * as socialStyle from '../css/socials.module.scss'
import { OutboundLink } from "gatsby-plugin-google-analytics"

const SocialmediaLinks = ({direction, addClasses, iconsOnly, iconSize}) => {


	const linkDirection = () => {
		if (direction && direction === 'col') {
			return direction + ' ' + 'space-y-4'
		}

		return 'row' + ' ' + 'space-x-4'
	}

	const socialClasses = () => {
		if (addClasses) {
			return addClasses
		}

		return ''
	}

	const brandSize = () => {
		if (iconSize === 'lg') {
			return `${socialStyle.sizeLg}`
		} else if (iconSize === 'xs') {
			return `${socialStyle.sizeXs}`
		} else if (iconSize === 'sm') {
			return `${socialStyle.sizeSm}`
		} else if (iconSize === 'xl') {
			return `${socialStyle.sizeXl}`
		}

		return `${socialStyle.sizeBase}`
	}

	return (
		<ul className={`m-0 p-0 flex flex-${linkDirection()} ${socialClasses()} ${socialStyle.socialList} ${brandSize()}`}>

		{
			socialmediaData.map(social => {
				const socialSlug = social.name.replace(/\s+/g, '-').toLowerCase()

				if (! social.link ) return

				return (
					<li key={socialSlug} className={`${socialSlug}-link`}>
					<OutboundLink href={social.link} target="_blank" rel="noopener noreferrer" className={`flex flex-row items-center ${socialStyle.socialLink}`} title={`Follow Höhn on ${social.name}`}>
						{ iconsOnly === true &&
							social.icon
						}
						{ iconsOnly !== true &&
							social.name
						}
					</OutboundLink>
					</li>
				)
			})
		}
		</ul>

	)
}

export default SocialmediaLinks
