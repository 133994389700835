import React, { useState, useEffect } from 'react'
import { Transition } from "@headlessui/react";
import { Link } from 'gatsby'
// import LogoIcon from '../../svg/LogoIcon';
// import Button from '../Button';
import SocialmediaLinks from '../SocialmediaLinks';
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { useMediaQuery } from 'react-responsive'
// import useElementSize from '../../utils/useElementSize';


const Header = ( {menuLinks, siteBrand, contactToggle, contactOpen, className } ) => {

	// if (typeof window === "undefined") {
	//     return <p>Server Render</p>
	//  }

  const [isOpen, setIsOpen] = useState(false);

  // const [contactOpen, contactIsOpen] = useState(false);
  // const toggleContact = () => contactIsOpen(!contactOpen);

//   const aboveMd = useMediaQuery({ query: '(min-width: 768px)' })

const headerClassName = `relative md:absolute z-40 top-0 md:left-0 md:right-0 bg-transparent duration-300`

//   const html = document.querySelector('html')

	 useEffect(() => {
	  const html = document.querySelector('html')
	  contactOpen ? (html.style.overflow = 'hidden') : (html.style.overflow = 'unset')
	 }, [contactOpen])


 return (
	<>
  <header className={className ? [headerClassName, className].join(" ") : headerClassName}>
    <div className="container-lg flex flex-row justify-between items-center md:items-start mx-auto py-2 md:px-8 px-4">
      <div className="flex md:flex-auto items-start flex-col text-5xl md:text-6xl xl:text-7xl text-black">
        <h1 className="uppercase font-black leading-none">

        <Link className="text-black hover:text-black font-brand font-normal leading-none inline" to="/">{siteBrand}</Link>
        </h1>
        <div className={`hidden md:flex md:flex-row md:pl-1 md:mt-2 justify-start font-light items-center space-x-4 lg:space-x-6 nav-menu`}>
        {menuLinks.map((link, index) => {
			const linkName = link.name
			const linkSlug = linkName.toLowerCase()

			return (

				  <AniLink fade duration={0.45} to={link.link} key={index} partiallyActive={link.partActive} className={`${link.linkClass} text-black text-base lowercase  ${linkSlug}-item`}
					  activeStyle={{textDecoration: 'underline', textUnderlineOffset: '3px', textDecorationThickness: '1px'}}
					  >
					  {link.name}
					</AniLink>
  			  )

		  }

		  )}

		  <button className={`text-black font-light text-base lowercase  contactpopout-item ${contactOpen ? 'pointer-events-none' : 'pointer-events-auto'}`} onClick={contactToggle}>
			  Contact
		  </button>
        </div>

      </div>

	  <SocialmediaLinks addClasses="hidden md:flex md:justify-end justify-center md:pt-5" iconsOnly={true} iconSize="md" />

      <div className="flex md:hidden space-x-4 lg:space-x-8 font-medium text-lg uppercase">

        <button
		  onClick={() => setIsOpen(!isOpen)}
		  type="button"
		  className="bg-transparent inline-flex items-center justify-center -mr-2 text-black hover:text-black focus:outline-none focus:ring-none"
		  aria-controls="mobile-menu"
		  aria-expanded="false"
			>
			  <span className="sr-only">Open main menu</span>
			  {!isOpen ? (
				<svg
				  className="block h-10 w-10"
				  xmlns="http://www.w3.org/2000/svg"
				  fill="none"
				  viewBox="0 0 24 24"
				  stroke="currentColor"
				  aria-hidden="true"
				>
				  <path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="0.85"
					d="M4 6h16M4 12h16M4 18h16"
				  />
				</svg>
			  ) : (
				<svg
				  className="block h-10 w-10"
				  xmlns="http://www.w3.org/2000/svg"
				  fill="none"
				  viewBox="0 0 24 24"
				  stroke="currentColor"
				  aria-hidden="true"
				>
				  <path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="0.85"
					d="M6 18L18 6M6 6l12 12"
				  />
				</svg>
			  )}
			</button>


      </div>
    </div>
    <Transition
	  show={isOpen}
	  enter="transition ease-out duration-100 transform"
	  enterFrom="opacity-0 scale-95"
	  enterTo="opacity-100 scale-100"
	  leave="transition ease-in duration-75 transform"
	  leaveFrom="opacity-100 scale-100"
	  leaveTo="opacity-0 scale-95"
	>
    <div className="md:hidden" id="mobileMenu">
		<div className="container mx-auto md:px-8 px-4">
	      <nav className="flex flex-col items-end text-right space-y-2 pt-10 pb-20">
	        {menuLinks.map((link, index) => {
				const linkName = link.name
				const linkSlug = linkName.toLowerCase()

				return (
	            <Link onClick={() => setIsOpen(false)} to={link.link} key={index} className={`text-black lowercase text-base ${linkSlug}-item`}>
	              {link.name}
	              </Link>
	          )})}
	      </nav>
		  </div>
    </div>

  </Transition>
  </header>


  </>
);

}

export default Header;
