import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CCLogo from "../svg/CCLogo"
import FactorLogo from "../svg/FactorLogo"
import CanadaLogo from "../svg/CanadaLogo"


const Partners = ( {isSplit} ) => {

	if (isSplit) {
		return (

			<div className={`flex justify-between w-full`}>
				<div className={`w-32 order-2`}>
					<CCLogo />
				</div>
				<div className={`w-56 order-1`}>
					<div className="flex flex-row items-center flex-nowrap space-x-3">
						<div className="w-40">
							<FactorLogo />
						</div>

						<CanadaLogo />
					</div>

				</div>
			</div>

		)
	}

	return (
		<div className={`flex sm:justify-start justify-center items-center space-x-10 md:space-x-7 lg:space-x-10`}>

			<div className={` flex-auto`}>
				<div className="flex flex-row items-center flex-nowrap space-x-3">
					<div className="w-20 sm:w-24 md:w-20 lg:w-24">
						<FactorLogo />
					</div>


						<div className="w-32 sm:w-36 md:w-32 lg:w-36">
							<CanadaLogo />
						</div>
				</div>

			</div>
			<div className={` flex-auto w-24`}>
				<CCLogo />
			</div>
		</div>
	)
}

export default Partners
