import React from "react"


const FactorLogo = () => {

	return (

	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="partnerLogo"
	 viewBox="0 0 228.1 62.8">
<g>
	<path d="M42.5,1.3L23.4,48.8L42.5,1.3z"/>
	<polygon points="0.4,0.7 27.2,0.7 27.2,9.9 10.6,9.9 10.6,19.6 27.2,19.6 27.2,28.9 10.6,28.9 10.6,48.3 0.4,48.3 	"/>
	<polygon points="39.8,58.5 42.2,58.5 42.2,59.3 40.7,59.3 40.7,60.2 42.2,60.2 42.2,61 40.7,61 40.7,62.7 39.8,62.7 	"/>
	<path d="M50.7,0.6l19.4,47.6H59.7l-3.8-9.3H37.7l-3.7,9.3H23.7L42.7,0.6H50.7z M41.1,30.2h11.3l-5.7-14L41.1,30.2z"/>
	<path d="M104.7,3.2v11.6c0,0-7.7-5.1-14-4.6c-6.4,0.4-10.2,4.2-11.7,9.8c-1.5,5.6,0.1,12.4,3.3,15.2c3.2,2.8,9.8,6.9,22.4-0.7v11.5
		c0,0-22.2,10.2-33.6-8.6c0,0-10.6-17.5,5.6-32.4C76.7,5.1,86.5-5,104.7,3.2z"/>
	<path d="M105.2,51.2l0,1.3c0,0-0.9-0.6-1.7-0.5c-0.7,0.1-1.2,0.6-1.4,1.2c-0.2,0.7,0,1.4,0.4,1.8c0.4,0.3,1.1,0.8,2.6-0.1v1.4
		c0,0-1.2,0.5-2.1,0.3c-0.7-0.1-1.3-0.4-1.8-1.3c0,0-0.3-0.4-0.4-1.1c-0.1-0.8,0-1.8,1-2.7C101.9,51.4,103,50.2,105.2,51.2z"/>
	<path d="M195.4,51.2l0,1.3c0,0-0.9-0.6-1.7-0.5c-0.7,0.1-1.2,0.6-1.4,1.2c-0.2,0.7,0,1.4,0.4,1.8c0.4,0.3,1.1,0.8,2.6-0.1v1.4
		c0,0-1.2,0.5-2.1,0.3c-0.7-0.1-1.3-0.4-1.8-1.3c0,0-0.3-0.4-0.4-1.1c-0.1-0.8,0-1.8,1-2.7C192.1,51.4,193.2,50.2,195.4,51.2z"/>
	<path d="M147.3,58.7v1c0,0-0.7-0.4-1.2-0.4c-0.6,0-0.9,0.4-1,0.9c-0.1,0.5,0,1.1,0.3,1.3c0.4,0.3,0.9,0.6,2,0v1
		c0,0-0.8,0.3-1.4,0.2c-0.6,0-1.2-0.4-1.6-1c0,0-0.9-1.6,0.5-2.8C144.8,58.9,145.7,58,147.3,58.7z"/>
	<path d="M59.7,58.7v1c0,0-0.7-0.5-1.2-0.4c-0.6,0-1,0.4-1,0.9c0,0.3-0.1,0.9,0.3,1.3c0.3,0.3,0.9,0.6,2-0.1v1c0,0-0.8,0.3-1.4,0.2
		c-0.3,0-1-0.2-1.5-1c0,0-0.9-1.6,0.5-2.9C57.2,58.9,58.1,58,59.7,58.7z"/>
	<polygon points="107.3,0.6 139.3,0.6 139.3,10.8 128.4,10.8 128.4,48.2 118.1,48.2 118,10.8 107.3,10.8 	"/>
	<path d="M140.1,23c0,0,0.5-22,23.9-22.9c0,0,18.6-0.8,23.4,18.6c0,0,3.7,13.9-6.6,23.5c-10.3,9.7-23.3,6.4-27.4,4.4
		S139.5,38.2,140.1,23z M150.7,25.5c1.7,15.7,14.4,13.8,14.4,13.8c13.8-1.7,12-16.2,12-16.2c-1-14.3-13.4-13.4-13.4-13.4
		C149.8,10.1,150.7,25.5,150.7,25.5z"/>
	<path d="M209.1,0.7c14,0.5,14.6,12.2,14.6,12.2c0.7,12.6-8.8,15.6-8.8,15.6l13.3,19.8h-11.8l-12.3-18.4H203v18.4h-10.3V0.6
		L209.1,0.7z M203,9.1v12.4c0,0,10.5,1.3,10.3-6.2C213.2,7.8,203.9,9.3,203,9.1z"/>
	<path d="M184.8,50.8c1.7,0.1,1.7,1.4,1.7,1.4c0.1,1.5-1,1.8-1,1.8l1.6,2.3h-1.4l-1.4-2.2H184v2.2h-1.2v-5.6L184.8,50.8z M184,51.8
		v1.5c0,0,1.2,0.2,1.2-0.7C185.2,51.7,184.1,51.9,184,51.8z"/>
	<path d="M33.6,58.5c1.2,0,1.3,1.1,1.3,1.1c0.1,1.1-0.8,1.4-0.8,1.4l1.2,1.7h-1l-1.1-1.6H33v1.6h-0.9v-4.2L33.6,58.5z M33,59.3v1.1
		c0,0,0.9,0.1,0.9-0.5C33.9,59.1,33.1,59.3,33,59.3z"/>
	<path d="M44,58.5c1.2,0,1.3,1.1,1.3,1.1c0.1,1.1-0.8,1.4-0.8,1.4l1.2,1.7h-1l-1.1-1.6h-0.1v1.6h-0.9v-4.2L44,58.5z M43.5,59.3v1.1
		c0,0,0.9,0.1,0.9-0.5C44.4,59.1,43.6,59.3,43.5,59.3z"/>
	<path d="M203.8,50.9c1.7,0.1,1.7,1.4,1.7,1.4c0.1,1.5-1,1.8-1,1.8l1.6,2.3h-1.4l-1.4-2.2h-0.1v2.2h-1.2v-5.6L203.8,50.9z
		 M203.1,51.8v1.5c0,0,1.2,0.2,1.2-0.7S203.2,51.9,203.1,51.8z"/>
	<path d="M161.6,58.5c1.2,0,1.3,1.1,1.3,1.1c0.1,1.1-0.8,1.4-0.8,1.4l1.2,1.7h-1l-1.1-1.6h-0.1v1.6h-0.9v-4.2L161.6,58.5z
		 M161.1,59.3v1.1c0,0,0.9,0.1,0.9-0.5C162,59.1,161.2,59.3,161.1,59.3z"/>
	<path d="M89.4,58.5c1.2,0,1.3,1.1,1.3,1.1c0.1,1.1-0.8,1.4-0.8,1.4l1.2,1.7h-1L89,61.1h-0.1v1.6H88v-4.2L89.4,58.5z M88.9,59.3v1.1
		c0,0,0.9,0.1,0.9-0.5C89.8,59.1,89,59.3,88.9,59.3z"/>
	<path d="M108.4,58.5c1.2,0,1.3,1.1,1.3,1.1c0.1,1.1-0.8,1.4-0.8,1.4l1.2,1.7h-1l-1.1-1.6h-0.1v1.6H107v-4.2L108.4,58.5z
		 M107.9,59.3v1.1c0,0,0.9,0.1,0.9-0.5C108.8,59.1,107.9,59.3,107.9,59.3z"/>
	<path d="M130.3,58.5c1.2,0,1.3,1.1,1.3,1.1c0.1,1.1-0.8,1.4-0.8,1.4l1.2,1.7h-1l-1.1-1.6h-0.1v1.6h-0.9v-4.2L130.3,58.5z
		 M129.8,59.2v1.1c0,0,0.9,0.1,0.9-0.5C130.7,59.1,129.9,59.3,129.8,59.2z"/>
	<polygon points="-0.1,50.8 3.6,50.8 3.6,52 2.3,52 2.3,56.4 1.1,56.4 1.1,52 -0.1,52 	"/>
	<polyline points="7.2,56.4 7.2,54.2 5.2,54.2 5.2,56.4 4,56.4 4,50.8 5.2,50.8 5.2,53 7.2,53 7.2,50.8 8.4,50.8 8.4,56.4 	"/>
	<polyline points="10.3,52 10.3,53.1 12.3,53.1 12.3,54.2 10.3,54.2 10.3,55.3 12.2,55.3 12.3,56.4 9.1,56.4 9.1,50.8 12.3,50.8
		12.3,52 10.3,52 	"/>
	<polyline points="155.6,53.1 157.5,53.1 157.5,54.2 155.6,54.2 155.6,55.3 157.5,55.3 157.5,56.4 154.4,56.4 154.4,50.8
		157.5,50.8 157.5,52 155.6,52 	"/>
	<polyline points="188.7,53.1 190.7,53.1 190.7,54.2 188.7,54.2 188.7,55.3 190.7,55.3 190.7,56.4 187.5,56.4 187.5,50.8
		190.7,50.8 190.7,52 188.7,52 	"/>
	<polyline points="103.7,59.3 103.7,60.2 105.1,60.2 105.1,61 103.7,61 103.7,61.9 105.1,61.9 105.1,62.7 102.8,62.7 102.8,58.5
		105.1,58.5 105.1,59.3 103.7,59.3 	"/>
	<polyline points="158.3,59.3 158.3,60.2 159.8,60.2 159.8,61 158.3,61 158.3,61.9 159.8,61.9 159.8,62.7 157.4,62.7 157.4,58.5
		159.8,58.5 159.8,59.3 158.3,59.3 	"/>
	<polygon points="17.9,51.9 15.9,51.9 15.9,53.1 17.9,53.1 17.9,54.2 16,54.2 16,56.4 14.7,56.4 14.7,50.8 17.9,50.8 	"/>
	<path d="M23.9,53.7c-0.1,2-1.5,2.9-2.9,2.9c-1.4,0-2.7-1.2-2.7-2.9c0-1.4,1-2.9,2.8-2.9C23,50.8,24,52.4,23.9,53.7z M21.1,51.9
		c-1,0-1.5,0.8-1.6,1.7c0,1.1,0.6,1.8,1.6,1.7c1,0,1.5-0.7,1.6-1.7C22.8,52.7,22,51.9,21.1,51.9z"/>
	<path d="M55.8,53.7c-0.1,2-1.5,2.9-2.9,2.9c-1.4,0-2.7-1.2-2.7-2.9c0-1.4,1-2.9,2.8-2.9C54.8,50.8,55.8,52.4,55.8,53.7z M53,51.9
		c-1,0-1.5,0.8-1.6,1.7c0,1.1,0.6,1.8,1.6,1.7c1,0,1.5-0.7,1.6-1.7C54.6,52.7,53.9,51.9,53,51.9z"/>
	<path d="M49.9,60.6c0,1.4-1,2.1-2.2,2.1c-1.1,0-2.1-1-2-2.1c0-1.3,0.9-2.1,2.1-2.1C49.1,58.5,49.9,59.5,49.9,60.6z M47.8,59.3
		c-0.7,0-1.2,0.6-1.2,1.3c0,0.8,0.5,1.3,1.2,1.3c0.7,0,1.1-0.4,1.2-1.3C49,59.9,48.5,59.3,47.8,59.3z"/>
	<path d="M123.9,60.6c0,1.5-1.1,2.1-2.2,2.1c-1.2,0-2.1-1-2-2.1c0-1.3,1-2.1,2.1-2.1C123.1,58.5,123.9,59.4,123.9,60.6z M121.8,59.3
		c-0.7,0-1.2,0.6-1.2,1.3c0,0.8,0.5,1.3,1.2,1.3c0.7,0,1.1-0.4,1.2-1.3C123,59.9,122.5,59.3,121.8,59.3z"/>
	<path d="M174.8,53.7c0,1.2-0.7,2-1.4,2.5c-0.4,0.2-0.8,0.4-1.5,0.4c-0.5,0-1-0.1-1.6-0.5c-0.6-0.4-1.2-1.2-1.2-2.4
		c0-0.5,0.1-1,0.4-1.5c0.3-0.6,1.1-1.4,2.4-1.4c0.7,0,1.3,0.2,1.8,0.6C174.5,51.9,174.8,52.8,174.8,53.7z M172,51.9
		c-1,0-1.5,0.8-1.6,1.7c0,1.1,0.7,1.8,1.5,1.8c1,0,1.6-0.7,1.6-1.8C173.6,52.7,173,51.9,172,51.9z"/>
	<path d="M201.4,53.7c0,1.2-0.7,2-1.4,2.5c-0.4,0.2-0.8,0.4-1.5,0.4c-0.5,0-1-0.1-1.6-0.5c-0.6-0.4-1.2-1.2-1.2-2.4
		c0-0.5,0.1-1,0.4-1.5c0.3-0.6,1.1-1.4,2.4-1.4c0.7,0,1.3,0.2,1.8,0.6C201,51.9,201.4,52.8,201.4,53.7z M198.5,51.9
		c-1,0-1.5,0.8-1.6,1.7c0,1.1,0.7,1.8,1.5,1.8c1,0,1.6-0.7,1.6-1.8C200.1,52.7,199.5,51.9,198.5,51.9z"/>
	<path d="M136.2,60.6c-0.1,1.4-1,2.1-2.2,2.1c-1.1,0-2-0.8-2-2.1c0-1.1,0.7-2.2,2.1-2.2C135.5,58.5,136.2,59.6,136.2,60.6z
		 M134,59.3c-0.7,0-1,0.6-1,1.4c0,0.7,0.5,1.3,1.2,1.3c0.7,0,1.2-0.4,1.2-1.3C135.3,59.9,134.8,59.3,134,59.3z"/>
	<path d="M31.7,60.6c0,1.6-1.3,2.1-2.2,2.1c-1.2,0-2-0.9-2-2.1c0-1.1,0.8-2.2,2.1-2.2C31,58.5,31.7,59.6,31.7,60.6z M29.6,59.3
		c-0.7,0-1.2,0.6-1.2,1.3c0,0.7,0.5,1.3,1.2,1.3c0.6,0,1.2-0.5,1.2-1.3C30.8,59.8,30.3,59.3,29.6,59.3z"/>
	<path d="M24.5,50.8h1.2v3.4c0,0,0,1.1,1,1.1s1-0.9,1-1c0-0.2,0-3.4,0-3.4h1.2l0,3.7c0,0,0,1.9-2.2,1.9c0,0-2,0.1-2.2-2V50.8z"/>
	<path d="M17.5,58.5h0.9l0,2.6c0,0,0,0.7,0.7,0.7c0.8,0,0.7-0.6,0.7-0.7c0-0.1,0-2.6,0-2.6h0.9v2.8c0,0-0.1,1.4-1.6,1.4
		c0,0-1.5,0.2-1.7-1.5V58.5z"/>
	<path d="M30.8,52.8v3.6h-1.2v-5.6h1.2l2.6,3.6v-3.6h1.2v5.6h-1.2L30.8,52.8z"/>
	<path d="M57.5,52.8v3.6h-1.2v-5.6h1.2l2.6,3.6v-3.6h1.2v5.6h-1.2L57.5,52.8z"/>
	<path d="M176.5,52.8v3.6h-1.2v-5.6h1.2l2.6,3.6l0-3.6h1.2v5.6h-1.2L176.5,52.8z"/>
	<path d="M214.7,52.8v3.6h-1.2v-5.6h1.2l2.6,3.6l0-3.6h1.2v5.6h-1.2L214.7,52.8z"/>
	<path d="M89.1,52.8l0,3.6h-1.2v-5.6h1.2l2.6,3.6v-3.6h1.2v5.6h-1.2L89.1,52.8z"/>
	<path d="M112.4,52.8l0,3.6h-1.2v-5.6h1.2l2.6,3.6v-3.6h1.2v5.6H115L112.4,52.8z"/>
	<path d="M159.3,52.8v3.6h-1.2v-5.6h1.2l2.6,3.6v-3.6h1.2v5.6h-1.2L159.3,52.8z"/>
	<path d="M136.1,52.8v3.6h-1.2v-5.6h1.2l2.6,3.6v-3.6h1.2v5.6h-1.2L136.1,52.8z"/>
	<path d="M65.1,60v2.7h-0.9v-4.2l0.9,0l1.9,2.7v-2.7l0.9,0v4.2l-0.9,0L65.1,60z"/>
	<path d="M37.2,50.8c2.7,0.1,2.6,2.8,2.6,2.8c-0.1,2.9-2.8,2.8-2.8,2.8h-1.8v-5.6L37.2,50.8z M36.4,55.4l0.6,0
		c1.8-0.1,1.6-1.7,1.6-1.7c0-1.8-1.6-1.7-1.6-1.7l-0.6,0V55.4z"/>
	<path d="M74.1,58.5c2,0.1,2,2.1,2,2.1C76,62.8,74,62.7,74,62.7h-1.4v-4.2L74.1,58.5z M73.5,61.9l0.4,0c1.3-0.1,1.2-1.3,1.2-1.3
		c0-1.4-1.2-1.3-1.2-1.3l-0.4,0V61.9z"/>
	<path d="M116.1,58.5c2,0.1,2,2.1,2,2.1c-0.1,2.2-2.1,2.1-2.1,2.1h-1.4v-4.2L116.1,58.5z M115.5,61.9l0.4,0c1.3-0.1,1.2-1.3,1.2-1.3
		c0-1.4-1.2-1.3-1.2-1.3l-0.4,0V61.9z"/>
	<path d="M141.8,58.5c2,0.1,2,2.1,2,2.1c-0.1,2.2-2.1,2.1-2.1,2.1h-1.4v-4.2L141.8,58.5z M141.2,61.9l0.4,0c1.3-0.1,1.2-1.3,1.2-1.3
		c0-1.4-1.2-1.3-1.2-1.3l-0.4,0V61.9z"/>
	<path d="M208.6,50.8c2.7,0.1,2.6,2.8,2.6,2.8c-0.1,2.9-2.8,2.8-2.8,2.8l-1.8,0v-5.6L208.6,50.8z M207.8,55.4l0.5,0
		c0.6,0,1.1-0.3,1.3-0.6c0.4-0.5,0.3-1.2,0.3-1.2c0-1-0.5-1.4-0.8-1.5c-0.5-0.2-0.9-0.2-0.9-0.2l-0.4,0L207.8,55.4z"/>
	<path d="M124.4,50.9c0.4,0,0.7,0.1,0.9,0.1c0.6,0.2,1,0.6,1.3,1c0.5,0.7,0.5,1.7,0.5,1.7c0,0.8-0.2,1.3-0.5,1.7
		c-0.8,1.3-2.4,1.1-2.4,1.1h-1.8v-5.6L124.4,50.9z M123.6,55.4l0.5,0c0.2,0,0.9-0.1,1.3-0.5c0.4-0.5,0.3-1.2,0.3-1.2
		c0-0.7-0.2-1.1-0.5-1.4c-0.4-0.3-1.2-0.4-1.2-0.4l-0.5,0L123.6,55.4z"/>
	<path d="M39.4,56.4l2.2-5.6h0.9l2.3,5.6h-1.2l-0.4-1.1h-2.1l-0.4,1.1H39.4z M41.5,54.3l1.3,0l-0.7-1.6L41.5,54.3z"/>
	<path d="M96,62.7l1.7-4.2h0.7l1.7,4.2h-0.9l-0.3-0.8h-1.6l-0.3,0.8H96z M97.5,61.1l1,0L98,59.9L97.5,61.1z"/>
	<path d="M110.2,62.7l1.7-4.2h0.7l1.7,4.2h-0.9l-0.3-0.8l-1.6,0l-0.3,0.8H110.2z M111.8,61.1l1,0l-0.5-1.2L111.8,61.1z"/>
	<path d="M135.9,62.7l1.7-4.2h0.7l1.7,4.2h-0.9l-0.3-0.8h-1.6l-0.3,0.8H135.9z M137.5,61.1l1,0l-0.5-1.2L137.5,61.1z"/>
	<path d="M147.4,62.7l1.7-4.2h0.7l1.7,4.2h-0.9l-0.3-0.8h-1.6l-0.3,0.8H147.4z M149,61.1l1,0l-0.5-1.2L149,61.1z"/>
	<path d="M116.5,56.4l2.2-5.6h0.9l2.3,5.6h-1.2l-0.4-1.1h-2.2l-0.4,1.1H116.5z M118.6,54.3l1.3,0l-0.7-1.7L118.6,54.3z"/>
	<path d="M129,56.4l2.2-5.6h0.9l2.3,5.6h-1.2l-0.4-1.1h-2.2l-0.4,1.1H129z M131.1,54.3l1.3,0l-0.7-1.7L131.1,54.3z"/>
	<path d="M145.2,56.4l2.2-5.6h0.9l2.3,5.6h-1.2l-0.4-1.1h-2.1l-0.4,1.1H145.2z M147.3,54.3l1.3,0l-0.7-1.6L147.3,54.3z"/>
	<path d="M63.5,56.4l2.2-5.6h0.9l2.3,5.6h-1.2l-0.4-1.1h-2.1l-0.4,1.1H63.5z M65.6,54.3l1.3,0l-0.7-1.6L65.6,54.3z"/>
	<path d="M105.4,56.4l2.2-5.6h0.9l2.3,5.6h-1.2l-0.4-1.1H107l-0.4,1.1H105.4z M107.4,54.3l1.3,0l-0.7-1.6L107.4,54.3z"/>
	<path d="M59.9,62.7l1.7-4.2h0.7l1.7,4.2H63l-0.3-0.8h-1.6l-0.3,0.8H59.9z M61.4,61.1l1,0l-0.5-1.2L61.4,61.1z"/>
	<path d="M68.2,62.7l1.7-4.2h0.7l1.7,4.2h-0.9L71,61.9h-1.6l-0.3,0.8H68.2z M69.7,61.1l1,0l-0.5-1.2L69.7,61.1z"/>
	<path d="M75.7,62.7l1.7-4.2h0.7l1.7,4.2h-0.9l-0.3-0.8H77l-0.3,0.8H75.7z M77.3,61.1l1,0l-0.5-1.2L77.3,61.1z"/>
	<polygon points="44.4,50.8 48.1,50.8 48.1,52 46.9,52 46.9,56.4 45.6,56.4 45.6,52 44.4,52 	"/>
	<polygon points="82,50.8 85.8,50.8 85.8,52 84.5,52 84.5,56.4 83.3,56.4 83.3,52 82,52 	"/>
	<polygon points="142,50.8 145.8,50.8 145.8,52 144.5,52 144.5,56.4 143.3,56.4 143.3,52 142,52 	"/>
	<polygon points="163.3,50.8 167.1,50.8 167.1,52 165.8,52 165.8,56.4 164.6,56.4 164.6,52 163.3,52 	"/>
	<polygon points="154.3,58.5 157.1,58.5 157.1,59.4 156.2,59.4 156.2,62.7 155.3,62.7 155.3,59.4 154.3,59.4 	"/>
	<polygon points="6.4,58.5 9.2,58.5 9.2,59.4 8.3,59.4 8.3,62.7 7.4,62.7 7.4,59.4 6.4,59.4 	"/>
	<polygon points="35.3,58.5 38.1,58.5 38.1,59.4 37.1,59.4 37.1,62.7 36.2,62.7 36.2,59.4 35.3,59.4 	"/>
	<polygon points="99.7,58.5 102.5,58.5 102.5,59.4 101.5,59.4 101.5,62.7 100.6,62.7 100.6,59.4 99.7,59.4 	"/>
	<rect x="48.4" y="50.8" width="1.2" height="5.6"/>
	<rect x="76.8" y="50.8" width="1.2" height="5.6"/>
	<rect x="127.5" y="50.9" width="1.2" height="5.6"/>
	<rect x="5.3" y="58.5" width="0.9" height="4.2"/>
	<rect x="91.4" y="58.5" width="0.9" height="4.2"/>
	<rect x="118.4" y="58.5" width="0.9" height="4.2"/>
	<rect x="211.6" y="50.8" width="1.2" height="5.6"/>
	<rect x="86.1" y="50.8" width="1.2" height="5.6"/>
	<path d="M70,51c0.5-0.3,1.2-0.2,1.2-0.2c0.8,0,1.3,0.6,1.3,0.6l-0.8,0.8L71.5,52c-0.4-0.2-0.7-0.1-0.7-0.1
		c-0.3,0.1-0.4,0.4-0.4,0.4c-0.1,0.5,0.7,0.8,0.7,0.8c0.8,0.4,1.1,0.6,1.1,0.6c0.5,0.5,0.5,1,0.5,1c0,0.7-0.5,1.2-0.5,1.2
		c-0.7,0.7-1.5,0.6-1.5,0.6c-0.9,0-1.7-0.8-1.7-0.8l0.8-0.9c0.2,0.2,0.5,0.4,0.5,0.4c0.5,0.2,0.7,0.1,0.7,0.1
		c0.3-0.2,0.4-0.5,0.4-0.5c0.1-0.4-0.5-0.6-0.7-0.7c-0.2-0.1-0.7-0.3-0.7-0.3s-0.4-0.2-0.6-0.4c-0.2-0.4-0.3-0.6-0.3-0.6
		C69.1,51.5,70,51,70,51z"/>
	<path d="M73.7,51c0.5-0.3,1.2-0.2,1.2-0.2c0.8,0,1.3,0.6,1.3,0.6l-0.8,0.8L75.2,52c-0.4-0.2-0.7-0.1-0.7-0.1
		c-0.3,0.1-0.4,0.4-0.4,0.4c-0.1,0.5,0.7,0.8,0.7,0.8c0.8,0.4,1.1,0.6,1.1,0.6c0.5,0.5,0.5,1,0.5,1c0,0.7-0.5,1.2-0.5,1.2
		c-0.7,0.7-1.5,0.6-1.5,0.6c-0.9,0-1.7-0.8-1.7-0.8l0.8-0.9c0.2,0.2,0.5,0.4,0.5,0.4c0.5,0.2,0.7,0.1,0.7,0.1
		c0.3-0.2,0.4-0.5,0.4-0.5c0.1-0.4-0.5-0.6-0.7-0.7c-0.2-0.1-0.7-0.3-0.7-0.3s-0.4-0.2-0.6-0.4C73,53,73,52.8,73,52.8
		C72.8,51.5,73.7,51,73.7,51z"/>
	<path d="M79.3,51c0.5-0.3,1.2-0.2,1.2-0.2c0.8,0,1.3,0.6,1.3,0.6l-0.8,0.8L80.7,52c-0.4-0.2-0.7-0.1-0.7-0.1
		c-0.3,0.1-0.4,0.4-0.4,0.4c-0.1,0.5,0.7,0.8,0.7,0.8c0.8,0.4,1.1,0.6,1.1,0.6c0.5,0.5,0.5,1,0.5,1c0,0.7-0.5,1.2-0.5,1.2
		c-0.7,0.7-1.5,0.6-1.5,0.6c-0.9,0-1.7-0.8-1.7-0.8l0.8-0.9c0.2,0.2,0.5,0.4,0.5,0.4c0.5,0.2,0.7,0.1,0.7,0.1
		c0.3-0.2,0.4-0.5,0.4-0.5c0.1-0.4-0.5-0.6-0.7-0.7c-0.2-0.1-0.7-0.3-0.7-0.3s-0.4-0.2-0.6-0.4c-0.2-0.4-0.3-0.6-0.3-0.6
		C78.3,51.5,79.3,51,79.3,51z"/>
	<path d="M225.3,51c0.5-0.3,1.2-0.2,1.2-0.2c0.8,0,1.3,0.6,1.3,0.6l-0.8,0.8l-0.3-0.2c-0.4-0.2-0.7-0.1-0.7-0.1
		c-0.3,0.1-0.4,0.4-0.4,0.4c-0.1,0.5,0.7,0.8,0.7,0.8c0.8,0.4,1.1,0.6,1.1,0.6c0.5,0.5,0.5,1,0.5,1c0,0.7-0.5,1.2-0.5,1.2
		c-0.7,0.7-1.5,0.6-1.5,0.6c-0.9,0-1.7-0.8-1.7-0.8l0.8-0.9c0.2,0.2,0.5,0.4,0.5,0.4c0.5,0.2,0.7,0.1,0.7,0.1
		c0.3-0.2,0.4-0.5,0.4-0.5c0.1-0.4-0.5-0.6-0.7-0.7c-0.2-0.1-0.7-0.3-0.7-0.3s-0.4-0.2-0.6-0.4c-0.2-0.4-0.3-0.6-0.3-0.6
		C224.3,51.5,225.3,51,225.3,51z"/>
	<path d="M151.9,60.4c-0.1-0.1-0.2-0.4-0.2-0.4c-0.2-1,0.6-1.3,0.6-1.3c0.4-0.2,0.9-0.2,0.9-0.2c0.7,0.1,1,0.4,1,0.4l-0.6,0.6
		l-0.2-0.1c-0.3-0.2-0.5-0.1-0.5-0.1c-0.2,0-0.3,0.3-0.3,0.3c-0.1,0.4,0.5,0.6,0.5,0.6c0.6,0.3,0.8,0.5,0.8,0.5
		c0.4,0.4,0.4,0.8,0.4,0.8c0,0.5-0.4,0.9-0.4,0.9c-0.5,0.5-1.1,0.5-1.1,0.5c-0.7,0-1.3-0.6-1.3-0.6l0.6-0.7c0.1,0.2,0.4,0.3,0.4,0.3
		c0.4,0.2,0.5,0.1,0.5,0.1c0.3-0.2,0.3-0.4,0.3-0.4c0-0.3-0.3-0.5-0.5-0.5c-0.2-0.1-0.5-0.2-0.5-0.2C152.1,60.6,152,60.5,151.9,60.4
		z"/>
	<path d="M163.8,60.4c-0.1-0.1-0.2-0.4-0.2-0.4c-0.2-1,0.6-1.3,0.6-1.3c0.4-0.2,0.9-0.2,0.9-0.2c0.7,0.1,1,0.4,1,0.4l-0.6,0.6
		l-0.2-0.1c-0.3-0.2-0.5-0.1-0.5-0.1c-0.2,0-0.3,0.3-0.3,0.3c-0.1,0.4,0.5,0.6,0.5,0.6c0.6,0.3,0.8,0.5,0.8,0.5
		c0.4,0.4,0.4,0.8,0.4,0.8c0,0.5-0.4,0.9-0.4,0.9c-0.5,0.5-1.1,0.5-1.1,0.5c-0.7,0-1.3-0.6-1.3-0.6l0.6-0.7c0.1,0.2,0.4,0.3,0.4,0.3
		c0.4,0.2,0.5,0.1,0.5,0.1c0.3-0.2,0.3-0.4,0.3-0.4c0-0.3-0.3-0.5-0.5-0.5c-0.2-0.1-0.5-0.2-0.5-0.2C164,60.6,163.9,60.5,163.8,60.4
		z"/>
	<path d="M15.2,58.6c0.5-0.2,1-0.2,1-0.2c0.5,0.1,0.9,0.4,0.9,0.4l-0.6,0.6l-0.2-0.1c-0.3-0.2-0.5-0.1-0.5-0.1
		c-0.2,0-0.3,0.3-0.3,0.3C15.5,60,16,60.1,16,60.1c0.7,0.3,0.8,0.5,0.8,0.5c0.4,0.4,0.3,0.8,0.3,0.8c0,0.5-0.4,0.9-0.4,0.9
		c-0.5,0.5-1.1,0.5-1.1,0.5c-0.7,0-1.2-0.6-1.2-0.6l0.6-0.7c0.1,0.2,0.4,0.3,0.4,0.3c0.4,0.2,0.6,0,0.6,0c0.3-0.2,0.2-0.4,0.2-0.4
		c0-0.3-0.4-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.5-0.2s-0.3-0.1-0.4-0.3c-0.2-0.3-0.2-0.5-0.2-0.5C14.5,59,15.2,58.6,15.2,58.6z"/>
	<path d="M81.1,58.6c0.4-0.2,0.9-0.2,0.9-0.2c0.6,0,1,0.4,1,0.4l-0.6,0.6l-0.2-0.1c-0.3-0.2-0.5-0.1-0.5-0.1
		c-0.2,0.1-0.3,0.3-0.3,0.3C81.4,60,82,60.2,82,60.2c0.6,0.3,0.8,0.4,0.8,0.4c0.4,0.4,0.3,0.8,0.3,0.8c0,0.5-0.3,0.9-0.3,0.9
		c-0.4,0.5-1.1,0.5-1.1,0.5c-0.7,0-1.3-0.6-1.3-0.6l0.6-0.7c0.1,0.2,0.4,0.3,0.4,0.3c0.3,0.2,0.5,0.1,0.5,0.1
		c0.3-0.1,0.3-0.3,0.3-0.3c0.1-0.3-0.3-0.5-0.5-0.6c-0.1,0-0.4-0.2-0.4-0.2s-0.4-0.2-0.5-0.3c-0.2-0.3-0.2-0.5-0.2-0.5
		C80.4,59,81.1,58.6,81.1,58.6z"/>
	<path d="M97.5,52.8c0,0-0.3-0.7-1-0.8c0,0-0.6-0.2-1.2,0.2c0,0-0.7,0.4-0.8,1.2c0,0-0.1,0.8,0.5,1.4c0,0,0.4,0.5,1.1,0.5
		c0,0,0.5,0,0.9-0.3c0,0,0.2-0.1,0.4-0.6h-1.3v-1.1h2.6l0,0.8c0,0,0,0.7-0.7,1.5c0,0-0.4,0.6-1.4,0.8c0,0-0.9,0.3-1.9-0.3
		c0,0-0.7-0.4-1.1-1.2c0,0-0.4-0.6-0.3-1.6c0,0,0-0.8,0.6-1.5c0,0,0.4-0.6,1.1-0.9c0,0,0.5-0.2,1-0.2c0,0,0.4,0,0.9,0.1
		c0,0,0.5,0.2,0.7,0.4c0,0,0.4,0.2,0.7,0.8L97.5,52.8z"/>
	<path d="M224.2,53.4h-2.6v1.1h1.3c-0.2,0.4-0.4,0.6-0.4,0.6c-0.4,0.3-0.9,0.3-0.9,0.3s-0.4,0-0.8-0.3c-0.2-0.1-0.4-0.4-0.4-0.4
		c-0.4-0.6-0.3-1.2-0.3-1.2c0.1-0.9,0.9-1.3,0.9-1.3c0.6-0.4,1.2-0.1,1.2-0.1c0.7,0.2,0.9,0.8,0.9,0.8l0.8-0.8
		c-0.4-0.6-0.7-0.8-0.7-0.8c-0.3-0.2-0.8-0.4-0.8-0.4c-0.5-0.1-0.9-0.1-0.9-0.1c-0.6,0-1.1,0.2-1.1,0.2c-0.7,0.3-1.2,1-1.2,1
		c-0.6,0.8-0.5,1.6-0.5,1.6c-0.1,1,0.5,1.7,0.5,1.7c0.1,0.3,0.5,0.6,0.5,0.6c1.2,1.1,2.7,0.5,2.7,0.5s0.7-0.1,1.2-0.8
		c0,0,0.2-0.3,0.4-0.6c0,0,0.2-0.4,0.2-0.8S224.2,53.4,224.2,53.4z"/>
	<polygon points="151.1,50.8 152.3,50.8 152.3,55.2 154,55.2 154,56.4 151.1,56.4 	"/>
	<polygon points="0.2,58.5 1,58.5 1.6,61.4 2.3,58.5 2.9,58.5 3.6,61.4 4.3,58.5 5.1,58.5 4.1,62.7 3.2,62.7 2.6,60.2 2,62.7
		1.2,62.7 	"/>
	<polygon points="9.5,58.5 10.4,58.5 10.4,60.1 11.9,60.1 11.9,58.5 12.8,58.5 12.8,62.7 11.9,62.7 11.9,61 10.4,61 10.4,62.7
		9.5,62.7 	"/>
	<path d="M11,58.4"/>
	<path d="M21.4,58.4l1.6,0c0,0,1,0,1.1,1.1c0,0,0.2,1.3-1.4,1.5l-0.5,0v1.6h-0.9V58.4z M22.3,59.2v1.1h0.4c0,0,0.5,0,0.5-0.5
		c0,0,0-0.5-0.6-0.5L22.3,59.2z"/>
	<path d="M24.5,58.5l1.6,0c0,0,1,0,1.1,1.1c0,0,0.2,1.3-1.4,1.5l-0.5,0v1.6h-0.9V58.5z M25.4,59.3v1.1h0.4c0,0,0.5,0,0.5-0.5
		c0,0,0-0.5-0.6-0.5L25.4,59.3z"/>
	<polygon points="50.1,62.7 50.6,58.5 51.7,58.5 52.5,61 53.3,58.5 54.3,58.5 54.9,62.7 54,62.7 53.6,59.7 52.7,62.7 52.3,62.7
		51.3,59.7 51,62.7 	"/>
	<path d="M79.2,58.5h0.9v0.9c0,0,0,0.4-0.5,0.9l-0.4-0.3c0,0,0.3-0.3,0.3-0.7h-0.4V58.5z"/>
	<path d="M84.9,58.5h1.6c0,0,1,0,1.2,1.1c0,0,0.2,1.4-1.4,1.5l-0.5,0v1.6h-0.9V58.5z M86,60.3c0.4,0,0.4-0.1,0.5-0.2
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.4,0v1.1L86,60.3z"/>
	<polygon points="92.5,58.5 93.4,58.5 94.5,61.3 95.6,58.5 96.5,58.5 94.9,62.7 94.1,62.7 	"/>
	<path d="M95,57.8"/>
	<path d="M126.6,59.2"/>
	<path d="M126.7,60.1"/>
	<path d="M126.7,59.2"/>
	<path d="M125.7,58.5h1.4c0,0,0.5,0,0.6,0.1c0,0,0.2,0.1,0.3,0.2c0,0,0.2,0.2,0.2,0.4c0,0,0.1,0.3,0,0.5c0,0,0,0.1-0.1,0.3
		c0,0-0.2,0.2-0.3,0.3c0,0,0.4,0.1,0.6,0.4c0,0,0.2,0.3,0.2,0.6c0,0,0,0.4-0.1,0.7c0,0-0.1,0.3-0.5,0.5c0,0-0.3,0.1-0.6,0.1
		c-0.3,0-1.6,0-1.6,0V58.5z M126.6,60.1c0,0,0.7,0.1,0.7-0.5c0-0.6-0.7-0.5-0.7-0.5V60.1z M126.6,62c0.7,0,1.1-0.1,1.1-0.7
		c-0.1-0.6-1.1-0.5-1.1-0.5V62z"/>
</g>
</svg>

	)
}

export default FactorLogo
