import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Partners from "../Partners"
import SocialmediaLinks from '../SocialmediaLinks';
import ModeToggler from '../ModeToggler'
import { footer } from '../../css/footer.module.scss'

const Footer = ( {siteTitle, menuLinks, siteBrand, contactToggle}) => {



	return (
		<>

		<footer className={`py-16 border-t border-black duration-300 relative block z-10 ${footer}`}>
		<div className="container-lg md:px-8 px-4 mx-auto space-y-8">
			<div className="flex flex-col md:flex-row md:flex-nowrap flex-wrap md:items-center">
				<div className="flex-auto md:order-1 order-2  space-y-5 md:space-y-6">
					<SocialmediaLinks addClasses="pt-10 pb-5 md:pt-0 md:pb-0 md:px-0 px-5 space-x-0 md:space-x-3 lg:space-x-5 md:justify-start justify-around" iconsOnly={true} iconSize="lg" />
					<p className="m-0 p-0 text-sm tracking-normal md:text-left text-center">&copy; { new Date().getFullYear() } Eric Haynes. All rights reserved.</p>
				</div>
				<div className="flex-auto md:order-2 order-1 space-y-5 md:space-y-6">

				<div className="hidden md:block text-center md:text-right space-x-5">
				{
					menuLinks.map((link, index) => {

						return (
								<AniLink fade duration={0.45} to={link.link} partiallyActive={link.partActive} key={index} className={`${link.linkClass} text-sm text-black lowercase font-light`}
   		   					  >
							  {link.name}
							</AniLink>
						)

					})
				}
				<button className={`md:inline-block hidden text-black font-light text-sm lowercase contactpopout-item`} onClick={contactToggle}>
				 Contact
			 </button>
				</div>
				<div className="w-64 flex md:justify-end justify-center ml-auto mr-auto md:ml-auto md:mr-0">
					<Partners />
				</div>

				</div>
			</div>
			<div className="flex flex-row md:justify-between justify-around items-start">
				<div className={`space-x-3`}>
					<AniLink className="text-xs underline" fade to={`/privacy`}>Privacy Policy</AniLink>
					<AniLink className="text-xs underline" fade to={`/gallery`}>Gallery</AniLink>
				</div>
				<ModeToggler />
			</div>
		</div>
		</footer>
		</>
	)
}

export default Footer
