import React from 'react'
import { Spotify, Apple, Amazon, Bandcamp, Soundcloud, Youtube, Tiktok, Facebook, Twitter, Instagram, Beatport } from '@icons-pack/react-simple-icons';
import * as socialStyle from '../css/socials.module.scss'

const SocialMediaData = [
	{
      name: 'Spotify',
      link: 'https://open.spotify.com/artist/05YiYkc5jTBQOjP5e10g7u',
	    icon: <Spotify className={socialStyle.socialIcon} />,
    },
    {
      name: 'Apple Music',
      link: 'https://music.apple.com/us/artist/h%C3%B6hn/1660652549',
	    icon: <Apple className={socialStyle.socialIcon} />,
    },
    {
      name: 'Amazon Music',
      link: null,
	    icon: <Amazon className={socialStyle.socialIcon} />,
    },
    // {
    //   name: 'Bandcamp',
    //   link: 'http://hohn.bandcamp.com/',
	  //   icon: <Bandcamp className={socialStyle.socialIcon} />,
    // },
    {
      name: 'SoundCloud',
      link: 'https://soundcloud.com/hohn_music',
	    icon: <Soundcloud className={socialStyle.socialIcon} />,
    },
    {
      name: 'YouTube',
      link: 'https://www.youtube.com/@hohn',
	    icon: <Youtube className={socialStyle.socialIcon} />,
    },
    {
      name: 'TikTok',
      link: 'https://www.tiktok.com/@hohn_music',
	    icon: <Tiktok className={socialStyle.socialIcon} />,
    },
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/hohn_music/',
	    icon: <Instagram className={socialStyle.socialIcon} />,
    },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/musicbyhohn',
	    icon: <Facebook className={socialStyle.socialIcon} />,
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/hohnmusic',
	    icon: <Twitter className={socialStyle.socialIcon} />,
    },
    {
      name: 'Beatport',
      link: 'https://www.beatport.com/search?q=hohn',
	    icon: <Beatport className={socialStyle.socialIcon} />,
    }
    
]

export default SocialMediaData
