import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { modeToggler, isDark, isLight } from "../css/btn.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'

export default function ModeToggler() {


	return (

		<div>
		<ThemeToggler>
			{({ theme, toggleTheme }) => (

			  <button
			  onClick={() =>
				  theme === 'dark' ? toggleTheme("light") : toggleTheme("dark")
			  }
			  className={`${modeToggler} ${theme === 'dark' ? isDark : isLight}`}
			  >

				<FontAwesomeIcon size={`lg`} icon={theme === 'dark' ? faMoon : faSun} />
			  </button>
			)}
		  </ThemeToggler>

		</div>
	)
}
